import moment from 'moment';
import ServiceCalculator from './funnels/Services/calculators/services';
import utils from './funnels/Services/utils';
import axios from 'axios';
import handymanServices from './funnels/Services/handyman';
import config from './config';
import { BeStfSlot, EActions, SelectionLimits, SetTimesLoadedForDuration, TenantTimeSlotsMap, TimeSlotMapItem, TimeSlotsMap, TransformSlotDateOptions, TransformSlotForDisplayOptions, TransformSlotTimeOptions } from './models/time';
import { BeSlot } from '@puls/puls-common-constants';

const timeSlotsUtils = {
    transformersForState: {
        STF: (slots: BeStfSlot[]) => {
            const timeslotsMap = slots.reduce((acc: any, timeSlot: BeStfSlot) => {
                if(!acc[timeSlot.date]) {
                    acc[timeSlot.date] = {
                        is_selected: false,
                        slots: []
                    }
                }
                const formattedSlots = timeSlot.slots.reduce((acc: any, slot: number) => {
                    const timeName = timeSlotsUtils.formatTimeSlot(slot);
                    acc.push({
                        date: timeSlot.date,
                        start_time: slot,
                        time_slot_name: timeName,
                        is_enabled: true,
                        withStf: true,
                        is_selected: false
                    })
                    return acc;
                }, [])
                acc[timeSlot.date].slots = formattedSlots;
                return acc;
            }, {});
            return timeslotsMap;
        },
        DEFAULT: (slots: BeSlot[]) => {
            const enrichedSlots = slots.reduce((acc: any, slot) => {
                if(!acc[slot.date]) {
                    acc[slot.date] = {
                        is_selected: false,
                        slots: []
                    };
                }
                acc[slot.date].slots.push({
                    ...slot,
                    is_selected: false
                });
                return acc;
            }, {})
            return enrichedSlots;
        }
    },
    sortSlots: {
        STF: (slots: BeStfSlot[]) => {
            slots.sort((a, b) => {
                const dateSort = getDateSort(a.date, b.date);
                return dateSort;
            });
            slots.forEach(item => {
                item.slots.sort((a, b) => a - b);
            });
            return slots;
        },
        DEFAULT: (slots: BeSlot[]) => {
            return slots.sort((a, b) => {
                const dateSort = getDateSort(a.date, b.date);
                if(dateSort) return dateSort;
                return a.start_time - b.start_time;
            });
        }
    },
    formatTimeSlot: (time: number, duration = 60) => {
        const TIME_ZERO_OBJECT = {hour:0,minute:0,second:0,millisecond:0}
		let startTime = moment().utc().set(TIME_ZERO_OBJECT).add(time, 'minutes').format('ha')
		let endTime = moment().utc().set(TIME_ZERO_OBJECT).add(time + (duration || 60), 'minutes').format('ha')
		return `${startTime} - ${endTime}`
	},
    isSingleSelection: (selectionLimits: SelectionLimits) => {
        const { date, time } = selectionLimits;
        return (time === 1 && date === 1)
    },
    getFirstEnabledTimeSlot: (timeSlotsMap: TimeSlotsMap) => {
        for (let date of timeSlotsUtils.getSortedDatesKeys(Object.keys(timeSlotsMap))) {
            let firstEnabled = timeSlotsUtils.getFirstEnableDateTimeSlot(timeSlotsMap[date].slots);
            if (firstEnabled) {
                return firstEnabled;
            }
        }
        return null;
    },
    getSortedDatesKeys(dates: string[]) {
        return dates.sort((a, b) => {
            return getDateSort(a, b);
        })
    },
    getFirstEnableDateTimeSlot: (slots : TimeSlotMapItem[]) => {
        return slots.find(slot => slot.is_enabled);
    },
    getNumberOfSelectedSlots: (slotsMap: TimeSlotsMap) => {
        if(!slotsMap) return;
        let count = 0;
        Object.keys(slotsMap).forEach((date) => {
            if(!slotsMap[date].is_selected) return;
            slotsMap[date].slots.forEach((slot) => {
                if(slot.is_selected) count++;
            })
        });
        return count;
    },
    hasTimeSlots: (slotsMap: TimeSlotsMap) => {
        return !!Object.keys(slotsMap).length;
    },
    getSingleSelectedDate: (slotsMap: TimeSlotsMap) => {
        return timeSlotsUtils.getSortedDatesKeys(Object.keys(slotsMap)).filter((date) => slotsMap[date].is_selected).reverse()[0];
    },
    getSingleSelectedTimeSlot: (slotsMap: TimeSlotsMap) => {
        const sortedDates = timeSlotsUtils.getSortedDatesKeys(Object.keys(slotsMap));
        const slotsMapLength = Object.keys(slotsMap).length;
        for(let i = slotsMapLength - 1; i >= 0; i--) {
            const selectedSlots = slotsMap[sortedDates[i]].slots.filter((slot) => slot.is_selected);
            if(selectedSlots.length) {
                return selectedSlots.reverse()[0];
            }
        }
    },
    generateDefaultTimeSlots: (date: string) => {
        return Array(14).fill(420).map((startTime: number, index: number) => {
            const increment = 60;
            const time = startTime + (increment * index);
            return {
                date,
                is_enabled: true,
                start_time: time,
                time_slot_name: timeSlotsUtils.formatTimeSlot(time),
                is_selected: false,
                duration: increment
            }
        })
    },
    getSelectedTimeSlots: (slotsMap: TimeSlotsMap) => {
        let selected: TimeSlotMapItem[] = [];
        Object.keys(slotsMap).forEach((date: string) => {
            if(slotsMap[date].is_selected) {
                selected = selected.concat(slotsMap[date].slots.filter((slot: TimeSlotMapItem) => slot.is_selected));
            }
        })
        return selected;
    },
    transformSlotsForDisplay: {
        DEFAULT: (timeSlotsMap: TimeSlotsMap, options: TransformSlotForDisplayOptions = {}) => {
            return Object.keys(timeSlotsMap).map((date: string) => {
                return {
                    date: getDateTransformedObject(date, timeSlotsMap, options.date),
                    slots: timeSlotsMap[date].slots.map((slot) => transformSingleSlotForDisplay(slot, options.time))
                };
            })
        },
        TENANT: (slotsMap: TenantTimeSlotsMap, options: TransformSlotForDisplayOptions = {}) => {
            return Object.keys(slotsMap).map((date) => {
                return {
                    date: getDateTransformedObject(date, slotsMap, options.date),
                    slots: Object.keys(slotsMap[date].slots).map((start_time) => transformSingleSlotForDisplay(slotsMap[date].slots[start_time], options.time))
                };
            })
        }
    },
    getInitReducerPayload: (options: any = {}) => {
        return {
            prepareData: async (_: any, getState: Function) => {
                const appState = getState();
                const { overrideMarketId } = options;
                const appointmentState = utils.getAppointmentState(appState)
                let marketId = overrideMarketId || appState.zipcode.marketId;
                let { selectedService, selectedServicesCart} = appointmentState; 
                if(!selectedService && (!selectedServicesCart || !selectedServicesCart.length)) return console.log('no selected service');
                let deviceId = utils.getSelectedDeviceId(appointmentState);
                if(!marketId || !deviceId) return console.log('no market or device');
                var services: any = []
                var appointment_funnel_id = null;
                // is multiple selection funnel
                if(selectedServicesCart && selectedServicesCart.length){
                    selectedServicesCart.forEach((selectedService: any) => {
                        services = services.concat(ServiceCalculator(selectedService));
                    })
                    appointment_funnel_id = selectedServicesCart[0].funnelConfig.funnelId;
                } else if(selectedService){
                    services =  ServiceCalculator(selectedService);
                    appointment_funnel_id = selectedService.funnelConfig.funnelId
                }
    
                if(!services.length) return console.log('no service id found');
                let data: any = {
                    market_id: marketId,
                    device_id: deviceId,
                    date: moment().format('YYYY-MM-DD'),
                    phpsessid: utils.getSessionId() || appState.config.client_uuid,
                    appointment_funnel_id,
                    services,
                    slot_duration: 1
                };
    
                if(appState.technician && appState.technician.isWantBeAssigend){
                    data.technician_id = appState.technician.id;
                    data.appointment_id = appState.config.from_appointment_id;
                }
                const appConfig: any = config;
                const timeSlotsRes = await axios({
                    method: 'POST',
                    url: `${appConfig.api}api/time/slots`,
                    data,
                    ...utils.getAxiosConfig()
                })
                return timeSlotsUtils.sortSlots.DEFAULT(timeSlotsRes.data.time_slots);
            },
            transformData: timeSlotsUtils.transformersForState.DEFAULT,
            onDone: (dispatch: ((action: SetTimesLoadedForDuration) => void), getState: Function) => {
                const appState = getState();
                const appointmentState = utils.getAppointmentState(appState)
                let { selectedService, selectedServicesCart} = appointmentState; 
                let numberOfHours;
                if(selectedServicesCart && selectedServicesCart.length) {
                    numberOfHours = handymanServices.calculateNumberOfHouers(selectedServicesCart[0].questions) || 1;
                } else if(selectedService) {
                    numberOfHours = handymanServices.calculateNumberOfHouers(selectedService.questions) || 1;
                }

                dispatch({
                    type: EActions.SET_TIMES_LOADED_FOR_DURATION,
                    payload: {
                        duration: numberOfHours
                    }
                })
            }
        }
    },
}

function getDateTransformedObject(date: string, slotsMap: any, dateOptions: TransformSlotDateOptions = {}) {
    return  {
        value: date,
        title: moment(date).format('ddd'),
        selected: !!slotsMap[date].is_selected,
        subtitle: moment(date).format('DD'),
        disabled: false,
        slotsSectionTitle: moment(date).format('dddd MM/DD'),
        bottomLabel: dateOptions.bottomLabel?.show(date) ? dateOptions.bottomLabel.label : '',
        badge: {
            show: !!dateOptions?.badge?.show(date),
        }
    }
}

function transformSingleSlotForDisplay(slot: TimeSlotMapItem, timeOptions: TransformSlotTimeOptions = {}) {
    const { time_slot_name, is_selected, is_enabled, start_time, time_slot_title } = slot;
    return {
        value: start_time,
        selected: !!is_selected,
        disabled: timeOptions.disabled ? timeOptions.disabled(slot) : !is_enabled,
        timeslot: {
            name: time_slot_name,
            side_image: { 
                show: !!timeOptions.side_image?.show(slot)
            }
        },
        title: time_slot_title,
        badge: { 
            show: !!timeOptions.badge?.show(slot)
        }
    }
}

function getDateSort(a: string, b: string) {
    const dateA = moment(a);
    const dateB = moment(b);

    if (dateA.isBefore(dateB)) return -1;
    if (dateA.isAfter(dateB)) return 1;
    return 0;
}

export default timeSlotsUtils;