import {funnelQuestionTypes as QuestionTypes} from '@puls/puls-common-constants'

export default (selectedService) => {
    let services = [];
    selectedService.questions.forEach((question)=> {
        switch (question.type) {
            case QuestionTypes.SINGLE_ANSWER:
                question.options.forEach(o => {
                    if (o.selected && o.withService){
                        services.push(o.withService);
                    }
                });
                break;
            default:

        }
    });

    return services
}
