export const INPUTS_VALIDATORS_STRATEGIES = {
    TEXT: 'text',
    EMAIL: 'email',
    PHONE: 'phone',
    ADDRESS: 'address',
    ADDRESS_GOOGLE: 'address_google'
}

export const EMAIL_UNIQUE_STRATEGIES = {
    ALWAYS_TRUE: 'ALWAYS_TRUE',
    CHECK_UNIQUNESS: 'CHECK_UNIQUNESS'
}