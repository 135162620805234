import utils from './utils.js';
import moment from 'moment';
import _ from 'lodash';
import {funnelUtilsConstants as constants} from '@puls/puls-common-constants'
import config from '../../config.js';

const funnelCodePostfix = 'create_appt';
const EXP_TIME = 15;
function getName(market, funnelId){
    return `${funnelCodePostfix}_${market}_${funnelId}_server_ctx`;
}

export default {
    ServerContextObject: {
        get: (market, funnelId) => {
            const CN = getName(market, funnelId);
            return utils.getCookieByName(CN);
        },
        set: (market, funnelId, ctx) => {
            const CN = getName(market, funnelId);
            let expires = moment().add(EXP_TIME, 'day').toDate();
            expires = new Date(expires).toUTCString();
            let newValue = ctx[constants.SERVER_AB_TEST_GROUP_ID];
            //for analytics analytics
            if(window.dataLayer){
                let content = _.cloneDeep(ctx);
                delete content[constants.SERVER_AB_TEST_GROUP_ID];
                let payload = {
                    "event": "A/B Test",
                    "ab test": content
                }
                window.dataLayer.push(payload);
            }

            let cookie = process.env.NODE_ENV === 'development' ? `${CN}=${newValue};` : `${CN}=${newValue}; expires=${expires}; path=/; SameSite=None` + config.cookieAddition;
            //"username=John Doe; expires=Thu, 18 Dec 2020 12:00:00 UTC; path=/;"
            document.cookie = cookie;
        }
    },
    CookieHandler: {
        get: (name) => {
            return utils.getCookieByName(name);
        },
        set: (name, value) => {
            let expires = moment().add(EXP_TIME, 'day').toDate();
            expires = new Date(expires).toUTCString();
            let cookie = process.env.NODE_ENV === 'development' ? `${name}=${value}; path=/;` : `${name}=${value}; expires=${expires}; path=/; SameSite=None` + config.cookieAddition ;
            //"username=John Doe; expires=Thu, 18 Dec 2020 12:00:00 UTC; path=/;"
            document.cookie = cookie;
        }
    }
}
