import { funnelQuestionTypes as QuestionTypes } from '@puls/puls-common-constants'

export default (selectedService) => {
    var services = [];
    selectedService.questions.forEach((question)=> {
        switch (question.type) {
            case QuestionTypes.MULTIPLE_ANSWER:
            case QuestionTypes.MULTIPLE_ANSWER_WITH_SECTIONS:
            case QuestionTypes.SINGLE_ANSWER:
                question.options.forEach((option) => {
                    if(option.selected && option.withService) services.push(option.withService)
                })
                break;
            default:

        }
    })

    return services
}
