import { funnelsActions as actionConstants, funnelUtilsConstants as utilsConstants } from '@puls/puls-common-constants';
import axios from 'axios';
import utils from '../Services/utils.js';
import Analytics from '../Services/analytics.js';
import config from '../../config.js';
const { flowPlanTypeId } = utilsConstants;

export default {
    chnageZipcodeInput : (payload) =>{
        return {
            type : actionConstants.CHNAGE_ZIP_CODE_INPUT,
            payload : payload
        }
    },
    validateZipCode : (payload) => {
        return (dispatch, getState) => {
            let appState = getState();
            const appointmentState = utils.getAppointmentState(appState)
            let selectedDeviceId = appointmentState.selectedService && appointmentState.selectedService.id
            if(!selectedDeviceId) return;

            let params = {
                zipCode: appState.zipcode.zipcode,
                deviceId: selectedDeviceId,
                appointment_funnel_code: appointmentState.selectedService.funnelConfig.funnelCode,
                session_id: utils.getSessionId() || appState.config.client_uuid
            };

            dispatch({
                type : actionConstants.CHANGE_ZIP_CODE_ACTIVITY,
                payload : true
            });

            axios.post(config.api + "api/market/byzipcode", params, utils.getAxiosConfig()).then(res => {
                if (res.data && (res.data.market_name || res.data.marketName)) {
                    Analytics.pushMarketEvent('Zip Code Validation', {market: (res.data.market_name || res.data.marketName), zipcode: appState.zipcode.zipcode});
                } else {
                    Analytics.pushMarketEvent('Zip Code Validation', {market: '', zipcode: appState.zipcode.zipcode});
                }

                snedZipcodeValidationEvent(res.data.isValidZipCode, appState.zipcode.zipcode);

                if(appState.zipcode.marketId &&  (res.data.marketId !== appState.zipcode.marketId)){
                    dispatch({
                        type: actionConstants.CLEAR_DATA_ON_MARKET_CHANGE
                    })
                }
                dispatch({
                    type: actionConstants.UPDATE_SUPPORTED_DEVICES_BY_ZIPCODE,
                    payload: {data: res.data}
                });
            }).catch(e => {
                Analytics.pushMarketEvent('Zip Code Validation', {market: '', zipcode: appState.zipcode.zipcode});
                var message = e.message.toString() || e.second_message;
                dispatch({
                    type: actionConstants.TOGGLE_ERROR_MODAL,
                    payload: {
                        message
                    }
                });
                dispatch({
                    type : actionConstants.CHANGE_ZIP_CODE_ACTIVITY,
                    payload : false
                });
            });
        }
    },
    validateMultipleZipcodes: (payload) => {
        return (dispatch, getState) => {
            let appState = getState();
            let device_ids;
            try {
                device_ids = appState.handyman.selectedService.funnelConfig.fetchDevicesPricesOnFirstTime;
            } catch (e) {
                console.log(e)
            }

            if(!device_ids) return console.log('no devices ids');
            let params = {
                zipcode: appState.zipcode.zipcode,
                device_ids,
                funnel_code: config.funnelCode,
                session_id: utils.getSessionId() || appState.config.client_uuid
            }

            dispatch({
                type : actionConstants.CHANGE_ZIP_CODE_ACTIVITY,
                payload : true
            });

            axios({
                method: 'POST',
                url: config.api + "api/market/supported_devices",
                data: params
            })
            .then(res => {
                if (res.data && (res.data.market_name || res.data.marketName)) {
                    Analytics.pushMarketEvent('Zip Code Validation', {market: (res.data.market_name || res.data.marketName), zipcode: appState.zipcode.zipcode});
                } else {
                    Analytics.pushMarketEvent('Zip Code Validation', {market: '', zipcode: appState.zipcode.zipcode});
                }

                if(appState.zipcode.marketId &&  (res.data.market_id !== appState.zipcode.marketId)){
                    dispatch({
                        type: actionConstants.CLEAR_DATA_ON_MARKET_CHANGE
                    })
                }

                // here we chaek if all of the devices are unsupported so we want to show that
                // the zipced is in valide
                var isAllDevicesUnsupported = !res.data.supportedDevices.reduce((acc, next) => {
                    return (acc || next.is_supported)
                }, false);

                // if all the devices are unsupported dispatch regular in valid zip event to block the user
                if(isAllDevicesUnsupported){
                    snedZipcodeValidationEvent(false, appState.zipcode.zipcode);
                    return dispatch({
                        type: actionConstants.UPDATE_SUPPORTED_DEVICES_BY_ZIPCODE,
                        payload: getUnsupportedObject()
                    });
                }

                // now need to check if the current device is supported if its not supported then
                var isCurrentDeviceIsSupported = false;
                res.data.supportedDevices.forEach((device) => {
                    if(device.id === appState.handyman.selectedService.id) isCurrentDeviceIsSupported = device.is_supported;
                });

                // if the device is not supported we want to launch regular unsupported
                if(!isCurrentDeviceIsSupported){
                    snedZipcodeValidationEvent(false, appState.zipcode.zipcode);
                    return dispatch({
                        type: actionConstants.UPDATE_SUPPORTED_DEVICES_BY_ZIPCODE,
                        payload: getUnsupportedObject()
                    });
                }

                // now the current device is valid and we need to update the rest for it
                // and filter in the ui if needed
                snedZipcodeValidationEvent(true, appState.zipcode.zipcode);
                return dispatch({
                    type: actionConstants.UPDATE_MULTIPLE_SUPPORTED_DEVICES_BY_ZIPCODE,
                    payload: res.data
                });
            })
            .catch(e => {
                console.error(e);
                Analytics.pushMarketEvent('Zip Code Validation', {market: '', zipcode: appState.zipcode.zipcode});
                var message = e.message.toString() || e.second_message;
                dispatch({
                    type: actionConstants.TOGGLE_ERROR_MODAL,
                    payload: {
                        message
                    }
                });
                dispatch({
                    type : actionConstants.CHANGE_ZIP_CODE_ACTIVITY,
                    payload : false
                });
            })
        }
    },
    validateSaleZipcode: () => {
        return (dispatch, getState) => {
            let appState = getState();
            let params = {
                zipcode: appState.zipcode.zipcode
            };

            const { pre_selected_plan_id } = appState.sales;
            if(pre_selected_plan_id) params.plan_id = pre_selected_plan_id;

            const plan_type_id = flowPlanTypeId[appState.config.flow]

            if(plan_type_id){
                params.plan_type_id = plan_type_id;
            }

            dispatch({
                type : actionConstants.CHANGE_ZIP_CODE_ACTIVITY,
                payload : true
            });
            
            axios.post(config.api + "api/market/sale-zipcode", params).then(res => {
                snedZipcodeValidationEvent(res.data.isValidZipCode, appState.zipcode.zipcode)
                dispatch({
                    type: actionConstants.UPDATE_SUPPORTED_DEVICES_BY_ZIPCODE,
                    payload: {data: res.data}
                });
            }).catch(e => {
                var message = e.message.toString() || e.second_message;
                dispatch({
                    type: actionConstants.TOGGLE_ERROR_MODAL
                });
                dispatch({
                    type : actionConstants.CHANGE_ZIP_CODE_ACTIVITY,
                    payload : false
                });
            });
        }
    }
}

function getUnsupportedObject(){
    // the 2 request for validation are diffrent so we need to include all fileds
    return {
        data: {
            isValidZipCode:false,
            marketId:0,
            market_id: 0,
            marketName: "",
            market_name: ""
        }
    }
}

function snedZipcodeValidationEvent(isValid, zipcode) {
    let event = 'CE ' + (isValid ? 'Zipcode supported' : 'Zipcode not supported');
    try {
        window.dataLayer.push({
            event,
            value: zipcode
        })
    } catch (error) {
        
    }
    
}
