import funnelUtils from "./funnels/utils"

const FUNNEL_IDS = {
    SMH: 3,
    TVI: 4,
    GD: 14,
    APR: 15,
    PLB: 17,
    HDM: 18,
    HVAC: 21
}

const ISSUES_IDS = {
    NOT_COOLING: 'NOT_COOLING',
    ICE_MAKER_NOT_WORKING: 'ICE_MAKER_NOT_WORKING',
    LEAKING: 'LEAKING',
    NOISY: 'NOISY',
    NOT_HEATING: 'NOT_HEATING',
    BURNING_SMELL: 'BURNING_SMELL',
    DRUM_ISSUE: 'DRUM_ISSUE',
    NO_POWER: 'NO_POWER',
    ODOR: 'ODOR',
    NOT_DRAINING: 'NOT_DRAINING',
    OTHER: 'OTHER'
}

export default {
    HUBSPOT_MAP: {
        [FUNNEL_IDS.APR]: 'APR',
        [FUNNEL_IDS.TVI]: 'TVI',
        [FUNNEL_IDS.HDM]: 'HDM',
        [FUNNEL_IDS.HVAC]: 'HVAC',
        [FUNNEL_IDS.PLB]: 'PLB',
        [FUNNEL_IDS.SMH]: 'SMH',
        [FUNNEL_IDS.GD]: 'GD'
    }, 
    FUNNEL_IDS,
    ISSUES_IDS,
    PRE_SELECTED_DEVICE_ISSUE_MAP: {
        [funnelUtils.DEVICES_IDS.REFRIGERATOR]: {
            'not_cooling': ISSUES_IDS.NOT_COOLING,
            'ice_maker_not_working': ISSUES_IDS.ICE_MAKER_NOT_WORKING,
            'leaking': ISSUES_IDS.LEAKING,
            'noisy': ISSUES_IDS.NOISY
        },
        [funnelUtils.DEVICES_IDS.DRYER]: {
            'not_heating': ISSUES_IDS.NOT_HEATING,
            'burning_smell': ISSUES_IDS.BURNING_SMELL,
            'drum_issue': ISSUES_IDS.DRUM_ISSUE,
            'no_power': ISSUES_IDS.NO_POWER
        },
        [funnelUtils.DEVICES_IDS.WASHER]: {
            'leaking': ISSUES_IDS.LEAKING,
            'odor': ISSUES_IDS.ODOR,
            'no_power': ISSUES_IDS.NO_POWER,
            'not_draining': ISSUES_IDS.NOT_DRAINING,
            'drum_issue': ISSUES_IDS.DRUM_ISSUE
        },
        [funnelUtils.DEVICES_IDS.DISHWASHER]: {
            'leaking': ISSUES_IDS.LEAKING,
            'not_draining': ISSUES_IDS.NOT_DRAINING,
            'odor': ISSUES_IDS.ODOR,
            'noisy': ISSUES_IDS.NOISY
        }
    }
    
}

