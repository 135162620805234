const DEVICES_CATEGORIES = {
    SYSTEM: [ 362, 363, 364, 365, 366, 367 ],
    RE_SYSTEM: [ 363, 365, 367, 366 ],
    APPLIANCES: [ 275, 270, 269, 266, 267, 268, 274, 308, 278, 359, 360, 361 ],
    RE_APPLIANCES: [ 267, 268, 269, 275, 270, 378, 266, 274, 308, 380, 389 ],
    HOME_ITEMS: [ 361, 359, 360 ],
    PREMIUM_OPTIONS: [ 379 ]
}

const COVERAGE_DEVICES_KEYS = {
    APPLIANCES: 'Appliances',
    SYSTEM: 'System',
    HOME_ITEMS: 'Home Items',
    PREMIUM_OPTIONS: 'Premium Options'
}

export default {
    PLANS_IDS: {
        NO_MEMBERSHIP: 0,
        CLICK_FIX_ESSENTIALS: 1,
        CLICK_FIX_STANDARD: 9,
        PROTECTION_ESSENTIALS: 7,
        PROTECTION_STANDARD: 8,
        WHOLE_HOME: 10,
        SYSTEM_PROTECTION: 11,
        APPLIANCES_PROTECTION: 12,
        HOME_SAVE: 13,
        HOME_DELUXE: 14,
        HOME_PREMIERE: 15,
        FREEMIUM: 16
    },
    PLANS: {
        REAL_ESTATE: [13, 15, 14],
        PROTECTION: [7, 8, 10, 11, 12],
        MEMEBRSHIP: [1, 9],
        DEFAULT: [11, 12, 10],
        ADMIN: [9, 7, 8, 10, 11, 12],
        BIZZ_DEV_SOUTHEAST: [9, 11, 12, 10]
    },
    FLOWS_IDS: {
        DEFAULT: 1,
        REAL_ESTATE: 2,
        BIZZ_DEV_SOUTHEAST: 3,
        NEW_CREATE_APPOINTMENT: 4
    },
    COVERAGE_DEVICES_CATEGORIES_MAP: {
        [COVERAGE_DEVICES_KEYS.APPLIANCES]: DEVICES_CATEGORIES.APPLIANCES,
        [COVERAGE_DEVICES_KEYS.SYSTEM]: DEVICES_CATEGORIES.SYSTEM
    },
    RE_COVERAGE_DEVICES_CATEGORIES_MAP: {
        [COVERAGE_DEVICES_KEYS.SYSTEM]: DEVICES_CATEGORIES.RE_SYSTEM,
        [COVERAGE_DEVICES_KEYS.APPLIANCES]: DEVICES_CATEGORIES.RE_APPLIANCES,
        [COVERAGE_DEVICES_KEYS.HOME_ITEMS]: DEVICES_CATEGORIES.HOME_ITEMS,
        [COVERAGE_DEVICES_KEYS.PREMIUM_OPTIONS]: DEVICES_CATEGORIES.PREMIUM_OPTIONS
    },
    ADDONS_CATEGORIES_IDS: {
        POOL_PUMP: 1,
        ADDITIONAL_APPLIANCES: 2,
        MISCELLANEOUS: 3,
        EXTERNAL_LINES: 4
    },
    PLAN_PERKS: {
        HANDYMAN_CREDIT: 1,
        HOME_HEALTH: 2,
        ALL_SERVICES_DISCOUNT: 3,
        CONCIERGE: 4
    },
    PLAN_PAYMENT_TERMS: {
		YEARLY: 'y',
		MONTHLY: 'm'
	}
}