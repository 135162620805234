import { Date, Time, BeSlot } from '@puls/puls-common-constants';

export interface GetTimeActionOptions {
    prepareData: Function,
    transformData: Function
    onDone?: Function
}

export interface BeStfSlot {
    date: string
    slots: number[]
}

export interface TimeSlotsMap {
    [key: string]: {
        is_selected: boolean
        slots: TimeSlotMapItem[]
    }
}

export interface TimeSlotMapItem extends BeSlot{
    is_selected: boolean
    withStf?: boolean
}

export interface TimeState {
    timeSlotsMap: TimeSlotsMap
    isLoading: boolean
    selectionLimits: SelectionLimits
    timeSlotsLoadedForDuration: number
    error: string
}

export interface SelectionLimits {
    date: number,
    time: number
}

export interface TenantTimeSlotsMap {
    [key: string]: {
        is_selected: boolean
        slots: {
            [key: string]: TimeSlotMapItem
        }
    }
}

export interface SelectedTimeSlot {
    date: Date
    time: Time
}

export interface TransformSlotForDisplayOptions {
    date?: TransformSlotDateOptions,
    time?: TransformSlotTimeOptions
}

export interface TransformSlotDateOptions {
    bottomLabel?: {
        show: Function
        label: string
    }
    badge?: {
        show: Function
    }
}

export interface TransformSlotTimeOptions {
    disabled?: Function
    side_image?: {
        show: Function
    }
    badge?: {
        show: Function
    }
}

// Actions creators

export enum EActions {
    SET_LOADING_TIME_SLOTS = 'SET_LOADING_TIME_SLOTS',
    SET_TIME_SLOTS_ERROR = 'SET_TIME_SLOTS_ERROR',
    SET_TIME_SLOTS = 'SET_TIME_SLOTS',
    TOGGLE_TIME = 'TOGGLE_TIME',
    TOGGLE_DATE = 'TOGGLE_DATE',
    CLEAR_TIME_SLOTS = 'CLEAR_TIME_SLOTS',
    UPDATE_SELECTION_LIMITS = 'UPDATE_SELECTION_LIMITS',
    SET_TIMES_LOADED_FOR_DURATION = 'SET_TIMES_LOADED_FOR_DURATION',
    UPDATE_PRE_SELECTED_TIMES = 'UPDATE_PRE_SELECTED_TIMES'
}

export interface SetLoadingTimesAction {
    type: EActions.SET_LOADING_TIME_SLOTS,
    payload: {
        isLoading: boolean
    }
}

export interface SetTimeSlotsErrorAction {
    type: EActions.SET_TIME_SLOTS_ERROR,
    payload: {
        message: string
    }
}

export interface SetTimeSlotsAction {
    type: EActions.SET_TIME_SLOTS,
    payload: {
        slots: TimeSlotsMap
    }
}

export interface ToggleTimeAction {
    type: EActions.TOGGLE_TIME,
    payload: {
        selectedSlot: SelectedTimeSlot
    }
}

export interface ToggleDateAction {
    type: EActions.TOGGLE_DATE,
    payload: {
        selectedDate: Date
    }
}

export interface ClearTimeSlotsAction {
    type: EActions.CLEAR_TIME_SLOTS
}

export interface UpdateSelectionLimitsAction {
    type: EActions.UPDATE_SELECTION_LIMITS,
    payload: SelectionLimits
}

export interface SetTimesLoadedForDuration {
    type: EActions.SET_TIMES_LOADED_FOR_DURATION,
    payload: {
        duration: number
    }
}

export interface UpdatePreSelectedTimes {
    type: EActions.UPDATE_PRE_SELECTED_TIMES,
    payload: {
        selectedSlots: BeSlot[]
    }
}

export type GetTimesActionDispatch = (action: SetLoadingTimesAction | SetTimeSlotsErrorAction | SetTimeSlotsAction) => void;
export type ToggleSelectedTimeActionDispatch = (action: ToggleTimeAction) => void;
export type ToggleSelectedDateActionDispatch = (action: ToggleDateAction) => void;
export type UpdatePreSelectedTimesDispatch = (action: UpdatePreSelectedTimes) => void;

export type ActionTypes = SetLoadingTimesAction | SetTimeSlotsErrorAction | SetTimeSlotsAction | ClearTimeSlotsAction | ToggleTimeAction | ToggleDateAction | UpdateSelectionLimitsAction | SetTimesLoadedForDuration | UpdatePreSelectedTimes;