import { funnelsActions as actionConstants} from '@puls/puls-common-constants';
import utils from '../Services/utils.js';
import axios from 'axios';
import { funnelUtilsConstants as utilsConstants } from '@puls/puls-common-constants';
import config from '../../config.js';
const { flowPlanTypeId } = utilsConstants;

export default {
    updateAddressFiled : (payload) => {
        return {
            type : actionConstants.UPDATE_USER_ADDRESS_FIELD,
            payload : payload
        };
    },
    updateGoogleData : (payload, resolvePlaceId = false)=>{
        // zip code second validation
        return (dispatch, getState) => {
            var geocoder = new window.google.maps.Geocoder();
            geocoder.geocode(resolvePlaceId ? {address: payload} : { 'placeId': payload }, (results, status) => {
                if(Array.isArray(results) && results[0]){
                    let _payload = results[0];
                    let appState = getState();
                    const appointmentState = utils.getAppointmentState(appState)
                    let { selectedService, selectedServicesCart } = appointmentState;
                    //take the zip code from google address compontnts (if the country is israel then we will inject zip code of 888 for test market)
                    // see the getPostalCode method
                    let zipCode = utils.getPostalCode(_payload ? _payload.address_components : {});
                    //if not device id update google data with false validation of zip code
                    // maybe we should tell the user that something is wrong - product dsecition
                    if(!zipCode || (!selectedService && (!selectedServicesCart || !selectedServicesCart.length))){
                        _payload.isGoogleZipCodeValid = false;
                        console.error('there is no zip code in the address components');
                        return dispatch({
                            type : actionConstants.UPDATE_GOOGLE_ADDRESS_DATA,
                            payload : _payload
                        })
                    }

                    let zipcodeParams = {
                        zipCode: zipCode,
                        deviceId: utils.getSelectedDeviceId(appointmentState),
                        appointment_funnel_code: utils.acuireFunnelCode(appointmentState),
                        country: 'US',
                        session_id: utils.getSessionId() || appState.config.client_uuid
                    };

                    //check if zip code that came from google places api is valid/supported
                    dispatch({
                        type : actionConstants.UPDATE_ADDRESS_VALIDATION_INDICATOR,
                        payload : true
                    });

                    axios.post(config.api + "api/market/byzipcode", zipcodeParams, utils.getAxiosConfig())
                    .then(res =>{
                        let isDevicesAreValid = res.data.isValidZipCode;
                        _payload.isGoogleZipCodeValid = isDevicesAreValid;
                        _payload.googleMarketId = res.data && (res.data.marketId);
                        return dispatch({
                            type : actionConstants.UPDATE_GOOGLE_ADDRESS_DATA,
                            payload : _payload
                        })
                    })
                    .catch(e => {
                        console.log(e);
                        // if there is error we say that the validatuion has faild
                        dispatch({
                            type : actionConstants.UPDATE_GOOGLE_ADDRESS_DATA,
                            payload : {isGoogleZipCodeValid : false}
                        })
                    })
                } else {
                    dispatch({
                        type : actionConstants.UPDATE_GOOGLE_ADDRESS_DATA,
                        payload : {isGoogleZipCodeValid : false}
                    })
                }
            })
        }
    },
    updateGoogleDataSale : (payload)=>{
        // zip code second validation
        return (dispatch, getState) => {
            var geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({ 'placeId': payload }, (results, status) => {
                if(Array.isArray(results) && results[0]){
                    let _payload = results[0];
                    let appState = getState();
                    let zipCode = utils.getPostalCode(_payload ? _payload.address_components : {});
                    //if not device id update google data with false validation of zip code
                    // maybe we should tell the user that something is wrong - product dsecition

                    let zipcodeParams = {
                        plan_id: appState.sales.selected_sale_id,
                        zipcode: zipCode,
                    };

                    const plan_type_id = flowPlanTypeId[appState.config.flow];

                    if(plan_type_id){
                        zipcodeParams.plan_type_id = plan_type_id;
                    }

                    axios.post(config.api + "api/sales/customer-credits", {zipcode: zipCode})
                        .then((data) => {
                            const tax = data.data;
                            dispatch({
                                type: actionConstants.UPDATE_TAX_DATA,
                                payload: tax
                            })
                        })
                        .catch(console.error)

                    //check if zip code that came from google places api is valid/supported
                    dispatch({
                        type : actionConstants.UPDATE_ADDRESS_VALIDATION_INDICATOR,
                        payload : true
                    });


                    axios.post(config.api + "api/market/sale-zipcode", zipcodeParams)
                    .then(res =>{
                        _payload.isGoogleZipCodeValid = res.data.isValidZipCode;
                        _payload.googleMarketId = res.data && (res.data.marketId);
                        return dispatch({
                            type : actionConstants.UPDATE_GOOGLE_ADDRESS_DATA,
                            payload : _payload
                        })
                    })
                    .catch(e => {
                        console.log(e);
                        // if there is error we say that the validatuion has faild
                        dispatch({
                            type : actionConstants.UPDATE_GOOGLE_ADDRESS_DATA,
                            payload : {isGoogleZipCodeValid : false}
                        })
                    })
                } else {
                    dispatch({
                        type : actionConstants.UPDATE_GOOGLE_ADDRESS_DATA,
                        payload : {isGoogleZipCodeValid : false}
                    })
                }
            })
        }
    }
}
