import mockDevicesData from "./mockDevicesData"
import plansConstants from "./plans"

const basePlanPerksList = [
    { id: plansConstants.PLAN_PERKS.HANDYMAN_CREDIT, text: '$200 handyman credit'},
    { id: plansConstants.PLAN_PERKS.HOME_HEALTH, text: '$100 home health check up'},
    { id: plansConstants.PLAN_PERKS.ALL_SERVICES_DISCOUNT, text: '15% off all home services'},
    { id: plansConstants.PLAN_PERKS.CONCIERGE, text: 'VIP concierge service'}
]

export default {
    plans:  {
        [plansConstants.PLANS_IDS.HOME_SAVE]: {
            id: plansConstants.PLANS_IDS.HOME_SAVE,
            description: 'Basic systems and appliances coverage',
            exclusivePerksValue: 200,
            exclusivePerkLabel: {
                color: '#B9DBEE',
                value: 200
            },
            perks: {
                list: basePlanPerksList.filter((item) => item.id !== plansConstants.PLAN_PERKS.HOME_HEALTH)
            }
        },
        [plansConstants.PLANS_IDS.HOME_DELUXE]: {
            id: plansConstants.PLANS_IDS.HOME_DELUXE,
            description: 'Standard home coverage plus premium options & food spoilage',
            exclusivePerksValue: 300,
            exclusivePerkLabel: {
                color: '#CAEEB9',
                value: 300
            },
            perks: {
                list: basePlanPerksList,
                mainColor: '#CAEEB9'
            }
        },
        [plansConstants.PLANS_IDS.HOME_PREMIERE]: {
            id: plansConstants.PLANS_IDS.HOME_PREMIERE,
            description: 'Total home protection with full appliance coverage & premium options',
            popular: true,
            exclusivePerksValue: 300,
            exclusivePerkLabel: {
                color: '#CAEEB9',
                value: 300
            },
            perks: {
                list: basePlanPerksList,
                mainColor: '#CAEEB9'
            }
        },
    },
    devices_additional_info: {
        379: 'Garage (hinges and springs) haul away crane fees permits modification recapture and reclamation equipment disposal structural modifications for HVAC and water heater replacements dishwasher extras (racks, rollers, and baskets) rekeys',
        389: 'Grants an additional $500 in coverage in the event an appliance cannot be repaired and needs to be replaced',
        367: 'Toilets, Fixtures Not Included',
        366: 'Fixtures Not Included'
    },
    basePlanPerksList,
    //TODO add addons info content when available - TBD
    addons_additional_info: {
        1672: ''
    },
    mocksData: mockDevicesData
}