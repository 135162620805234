const popular = 'The most popular';
const extra = 'Extra Installations';
const cables = 'Cables';
const connect = 'Connect Devices';

const utilsConstants = {
    COOKIES_NAMES: {
        LAST_SALE_ENTRY:'last_sales_funnel_entered',
        LAST_FUNNEL_ENTRY: 'last_funnel_entered'
    },
    SESSION_KEYS_NAMES: {
        LAST_SID_ENTERED: 'last_sid_entered'
    },
    LOCAL_KEYS_NAMES: {
        SNACKBAR_SHOWED: 'snackbar_showed'
    },
    YEAR_IN_MONTHS: 12,
    DIAGNOSTIC_SERVICE_ID: 364,
    DIAGNOSTIC_SERVICE_INFO: "How do service call fees work? A technician will review scope of work in-person and confirm their service rates with you. If you wish to move forward ",
    TRUST_AND_SERVICE_SERVICE_ID: 1451,
    SUBSCRIPTION_DISCOUNT_SERVICE_ID: 1456,
    EXPEDITE_SERVICE_ID: 1681,
    BIG_ASS_FAN_DEVICE_ID: 358,
    VERIFICATION: {
        SELECT_VERIFICATION_METHOD: 'SELECT_VERIFICATION_METHOD',
        VERIFY_CODE: 'VERIFY_CODE'
    },
    MAX_PURCHASED_HOURS_BY_CUSTONER: 10,
    SERVER_AB_TEST_GROUP_ID: 'group_mapping_id',
    TV_MOUNT: {
        SECTIONS: {
            THE_MOST_POPULAR: popular,
            EXTRA_INSTALLATIONS: extra,
            CABLES: cables,
            CONNECT_DEVICES: connect
        },
        FAKE_SERVICES: {
            DRY_WALL_WOOD_WALL_TYPE: 'DRY_WALL_WOOD_WALL_TYPE',
            ON_THE_WALL: 'ON_THE_WALL',
            ABOVE_A_FIREPLACE: 'ABOVE_A_FIREPLACE'
        }
    },
    ZIPCODE_PROPS: {
        placeholder: 'Enter your zip code',
        placeholderError: 'SORRY! We do not cover that area... yet!',
        type: 'tel',
        id: 'zipcode-input-item',
        validate: (value: any) => (value == '888') ,
    },
    PLANS: {
        REAL_ESTATE_PLANS: [13, 14, 15],
        PROTECTION_PLANS: [7, 8, 10, 11, 12],
        MEMEBRSHIP_PLANS: [1, 9]
    },
    PLANS_IDS: {
        NO_MEMBERSHIP: 0,
        CLICK_FIX_ESSENTIALS: 1,
        CLICK_FIX_STANDARD: 9,
        PROTECTION_ESSENTIALS: 7,
        PROTECTION_STANDARD: 8,
        WHOLE_HOME: 10,
        SYSTEM_PROTECTION: 11,
        APPLIANCES_PROTECTION: 12,
        HOME_SAVE: 13,
        HOME_DELUXE: 14,
        HOME_PREMIERE: 15
    },
    DEFAULT_PLAN_ID: 10,
    SERVCIES_TYPES: {
        DISCOUNT_SERVICE_ID: 3
    },
    MEMBERSHIP_MONTHLY_SERVICES_IDS: {
        CLICK_FIX_STANDARD: 1497
    },
    FUNNEL_IDS: {
        HANDYMAN: 18,
        TV: 4,
        HOME_APPLIANCES: 15
    },
    GENERAL_HANDYMAN_HOURS: 335,
    CARDS_TITLES_FIELDS: {
        REGULAR: 'REGULAR', 
        FIX_STANDARD: 'FIX_STANDARD',
        FIX_ESSETIALS: 'FIX_ESSETIALS',
        PROTECTION_STANDARD: 'PROTECTION_STANDARD',
        PROTECTION_ESSENTIALS: 'PROTECTION_ESSENTIALS'
    },
    APPLIANCES_SELECTION_IDS: {
        FULL_APPLIANCES: 1,
        KITCHEN_APPLIANCES: 2
    },
    PLANS_ADVANTAGES_INFO: {
        FULL_COVERAGE_AMOUNTS: 1,
        PARTIAL_COVERAGE_AMOUNTS: 2,
        SERVICES: 3
    },
    SALE_STEPPER_STEPS: {
        USER_DETAILS: 1,
        PLANS: 2,
        ADDRESS: 3
    },
    PAYMENT_TYPES: {
        MONTHLY: 'm',
        YEARLY: 'y'
    },
    REAL_ESTATE_USERS: {
        BUYER: 0,
        AGENT: 1,
        TITLE: 2
    },
    REAL_ESTATE_ISSUER: 'real_estate',
    MEMBERSHIP_PLANS_BY_FLOW: {
        DEFAULT: [11, 12, 10],
        ADMIN: [9, 7, 8, 10, 11, 12],
        REAL_ESTATE: [13 ,14, 15],
        BIZZ_DEV_SOUTHEAST: [9, 11, 12, 10]
    },
    FLOWS_IDS: {
        DEFAULT: 1,
        REAL_ESTATE: 2,
        BIZZ_DEV_SOUTHEAST: 3,
        NEW_CREATE_APPOINTMENT: 4
    },
    CHECKUP_SID: 343,
    HVAC_SID: 377,
    PRICE_VALUE_TYPE: {
		PERCENTAGES: 1,
		USD: 2
	},
    TODAYS_REPAIRS: 'todayrepairs',
    DEVICES_IDS: {
        REFRIGERATOR: 269,
        DRYER: 268,
        OVEN: 270,
        WASHER: 267,
        DISHWASHER: 266,
        COOKTOP: 272,
        FREEZER: 273,
        MICROWAVE: 275,
        WASHER_DRYER_COMBO: 284
    }, 
    DISCOUNT_SERVICE_ID: 12,
    HDM_TEST_DISCOUNT_PERCENT: 10,
    HDM_TEST_COUPON_CODE: 'expedite10',
    STRATEGY_TYPES: {  
        BLENDER: 'blender', 
        DEFAULT: 'default'
    }
}

const flowPlanTypeId =  { 
    [utilsConstants.FLOWS_IDS.REAL_ESTATE]: 3
}

export default { 
    ...utilsConstants, 
    flowPlanTypeId
}