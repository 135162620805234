import { cloneDeep } from 'lodash'
import timeSlotsUtils from '../../time-slots.js';
import { ActionTypes, EActions, TimeState } from '../../models/time.js';

const DEFAULT_DATE_SELECTION_LIMIT = 1;
const DEFAULT_TIME_SELECTION_LIMIT = 1;

const getInitialState = () :TimeState => {
    return {
        timeSlotsMap: {},
        isLoading: false,
        selectionLimits: {
            date: DEFAULT_DATE_SELECTION_LIMIT,
            time: DEFAULT_TIME_SELECTION_LIMIT,
        },
        timeSlotsLoadedForDuration: 0,
        error: ''
    }
};

const initialState = getInitialState();

export default (state = initialState, action: ActionTypes): TimeState => {
    const { type } = action;
    switch (type) {
        case EActions.CLEAR_TIME_SLOTS:
            return getInitialState();
        case EActions.SET_LOADING_TIME_SLOTS: {
            const { payload } = action;
            return {
                ...state,
                isLoading: payload.isLoading
            }
        }
        case EActions.SET_TIME_SLOTS: {
            const { payload } = action;
            const { slots } = payload;
            const isSingleSelection = timeSlotsUtils.isSingleSelection(state.selectionLimits);
            if(isSingleSelection) {
                const firstTimeSlot = timeSlotsUtils.getFirstEnabledTimeSlot(slots);
                if(firstTimeSlot) {
                    slots[firstTimeSlot.date].is_selected = true;
                    firstTimeSlot.is_selected = true;
                }
            }
            return {
                ...state,
                timeSlotsMap: slots,
                isLoading: false
            }
        }
        case EActions.TOGGLE_DATE: {
            const { payload } = action;
            const { selectedDate } = payload;
            const currentSelectedDateObj = state.timeSlotsMap[selectedDate.value];
            if(!currentSelectedDateObj) {
                state.timeSlotsMap[selectedDate.value] = {
                    is_selected: false,
                    slots: timeSlotsUtils.generateDefaultTimeSlots(selectedDate.value)
                }
            }
            const newTimeSlots = cloneDeep(state.timeSlotsMap);
            const isSingleSelection = timeSlotsUtils.isSingleSelection(state.selectionLimits);
            if(isSingleSelection) {
                Object.keys(newTimeSlots).forEach((date) => {
                    if(newTimeSlots[date].is_selected) {
                        newTimeSlots[date].slots = newTimeSlots[date].slots.map((slot) => ({...slot, is_selected: false}))
                    }
                    newTimeSlots[date].is_selected = date === selectedDate.value
                })
                const firstTimeSlot = timeSlotsUtils.getFirstEnableDateTimeSlot(newTimeSlots[selectedDate.value].slots);
                if(firstTimeSlot) {
                    firstTimeSlot.is_selected = true;
                }
            } else {
                const isDateSelected = selectedDate.selected;
                newTimeSlots[selectedDate.value].is_selected = !newTimeSlots[selectedDate.value].is_selected;
                if(isDateSelected) {
                    newTimeSlots[selectedDate.value].slots = newTimeSlots[selectedDate.value].slots.map((slot) => ({...slot, is_selected: false}))
                }
            }

            return {
                ...state,
                timeSlotsMap: newTimeSlots
            }
        }
        case EActions.TOGGLE_TIME: {
            const { payload } = action;
            const { selectedSlot } = payload;
            const newTimeSlotsMap = cloneDeep(state.timeSlotsMap);
            const isTimeSelected = selectedSlot.time.selected;
            const isSingleSelection = timeSlotsUtils.isSingleSelection(state.selectionLimits);
            if(isSingleSelection) {
                if(!isTimeSelected) {
                    newTimeSlotsMap[selectedSlot.date.value].slots = newTimeSlotsMap[selectedSlot.date.value].slots.map((slot) => ({...slot, is_selected: slot.start_time === selectedSlot.time.value}));
                }
            } else {
                newTimeSlotsMap[selectedSlot.date.value].slots = newTimeSlotsMap[selectedSlot.date.value].slots.map((slot) => slot.start_time === selectedSlot.time.value ? {...slot, is_selected: !slot.is_selected} : slot);
            }
            return {
                ...state,
                timeSlotsMap: newTimeSlotsMap
            }
        }
        case EActions.UPDATE_PRE_SELECTED_TIMES: {
            const { payload } = action;
            const updatedTimeSlotsMap = cloneDeep(state.timeSlotsMap);
            payload.selectedSlots.forEach((selectedSlot) => {
                if(updatedTimeSlotsMap[selectedSlot.date]) {
                    const foundedTime = updatedTimeSlotsMap[selectedSlot.date].slots.find((slot) => slot.start_time === selectedSlot.start_time);
                    if(foundedTime) {
                        updatedTimeSlotsMap[selectedSlot.date].is_selected = true;
                        if(foundedTime.is_enabled) foundedTime.is_selected = true;
                    }
                }
            })
            return {
                ...state,
                timeSlotsMap: updatedTimeSlotsMap
            }
        }
        case EActions.SET_TIMES_LOADED_FOR_DURATION: {
            const { payload } = action;
            return {
                ...state,
                timeSlotsLoadedForDuration: payload.duration
            }
        }
        case EActions.UPDATE_SELECTION_LIMITS: {
            const { payload } = action;
            return {
                ...state,
                selectionLimits: payload
            }
        }
        case EActions.SET_TIME_SLOTS_ERROR: {
            const { payload } = action;
            return {
                ...state,
                error: payload.message
            }
        }
        default:
            return state;
    }
}
