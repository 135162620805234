import timeSlotsUtils from '../../time-slots.js';
import ServicesCalculator from './calculators/services.js';
export default {
    validateReducer
}

function validateReducer(reducer, reducerStrategy){
    let call = validators[reducerStrategy];
    return  call && call(reducer);
}

let validators = {
    handyman : appointmentValidator,
    appointment: appointmentValidator,
    address : (reducer) =>{
        return reducer.isValid
    },
    userdetails : (reducer) =>{
        return reducer.isValid
    },
    time : (reducer) => {
        const numberOfSelectedTimes = timeSlotsUtils.getNumberOfSelectedSlots(reducer.timeSlotsMap);
        return !!numberOfSelectedTimes
    }
};

function appointmentValidator(reducer) {
    let { selectedService, selectedServicesCart } = reducer;
    if(!selectedService && (!selectedServicesCart || !selectedServicesCart.length)) return false;
    var services = [];
    if(selectedServicesCart && selectedServicesCart.length){
        selectedServicesCart.forEach(selectedService => {
            services = services.concat(ServicesCalculator(selectedService))
        })
    }

    if(selectedService){
        services = services.concat(ServicesCalculator(selectedService))
    }
    return !!services.length;
}