import utilsStrategies from "./funnels/utilsStrategies";
import { funnelUtilsConstants } from "@puls/puls-common-constants";
const { STRATEGY_TYPES } = funnelUtilsConstants;

class Initializer {
    params: any = {};
    type: any

    updateParams({type, params}: {type?: string, params: any}) {
        if (!this.type) {
            this.type = type || STRATEGY_TYPES.DEFAULT;
        }

        this.params = {
            ...this.params,
            ...params
        }
    }

    get strategy() { 
        return utilsStrategies[this.type]
    }
}

export default new Initializer()