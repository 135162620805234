import FieldConstants from './fields.js';
import { INPUTS_VALIDATORS_STRATEGIES } from '../inputsValidators.js';


export default {
    inputs: {
        [FieldConstants.FIRST_NAME]:{
            id: 'firstName',
            placeholder: 'First name',
            placeholderError: 'Please enter your first name',
            type: 'text',
            name: 'firstName',
            isValid: false,
            validateStrategy: INPUTS_VALIDATORS_STRATEGIES.TEXT,
            customeClass: ''
        },
        [FieldConstants.LAST_NAME]:{
            id: 'lastName',
            placeholder: 'Last name',
            placeholderError: 'Please enter your last name',
            type: 'text',
            name: 'lastName',
            isValid: false,
            validateStrategy: INPUTS_VALIDATORS_STRATEGIES.TEXT,
            customeClass: ''
        },
        [FieldConstants.EMAIL]:{
            id: 'email',
            placeholder: 'Email',
            placeholderError: 'Please enter a valid email',
            type: 'email',
            name: 'email',
            isValid: false,
            validateStrategy: INPUTS_VALIDATORS_STRATEGIES.EMAIL,
            customeClass: ''
        },
        [FieldConstants.PHONE]:{
            id: 'phone',
            placeholder: 'Phone',
            placeholderError: 'Please enter valid phone number',
            type: 'tel',
            name: 'phone',
            mask: '(999) 999-9999',
            maskChar: ' ',
            isValid: false,
            validateStrategy: INPUTS_VALIDATORS_STRATEGIES.PHONE,
            customeClass : ''
        },
        [FieldConstants.COMPANY_NAME]:{
            id: 'company',
            placeholder: 'Company name',
            placeholderError: 'Please enter company name',
            type: 'text',
            name: 'company',
            isValid: false,
            validateStrategy: INPUTS_VALIDATORS_STRATEGIES.TEXT,
            customeClass: ''
        }
    }
};