export default {
    devicesGroups: {
        "13": [
            {
                "title": "System",
                "devices": [
                    {
                        "id": 9001,
                        "title": "AC System",
                        "amount": 5000,
                        "included": true,
                        info: 'Including cooling system (5-ton capacity/RES use), heating system and ductwork'
                    },
                    {
                        "id": 363,
                        "title": "Heating System",
                        "amount": 5000,
                        "included": true
                    },
                    {
                        "id": 9002,
                        "title": "Ductwork",
                        "amount": 5000,
                        "included": true
                    },
                    {
                        "id": 365,
                        "title": "Interior Electrical System",
                        "amount": 1500,
                        "included": true
                    },
                    {
                        "id": 367,
                        "title": "Plumbing Stoppages",
                        "amount": 1500,
                        "included": true,
                        "info": "Including toilets"
                    },
                    {
                        "id": 366,
                        "title": "Plumbing Systems",
                        "amount": 1500,
                        "included": true,
                        "info": "Including fixtures"
                    }
                ]
            },
            {
                "title": "Appliances",
                "devices": [
                    {
                        "id": 267,
                        "title": "Washer",
                        "amount": 2000,
                        "included": false
                    },
                    {
                        "id": 268,
                        "title": "Dryer",
                        "amount": 2000,
                        "included": false
                    },
                    {
                        "id": 269,
                        "title": "Refrigerator",
                        "amount": 2000,
                        "included": false
                    },
                    {
                        "id": 275,
                        "title": "Built-In Microwave",
                        "amount": 1500,
                        "included": true
                    },
                    {
                        "id": 378,
                        "title": "Kitchen Exhaust Fan",
                        "amount": 2000,
                        "included": true
                    },
                    {
                        "id": 270,
                        "title": "Oven",
                        "amount": 2000,
                        "included": true
                    },
                    {
                        "id": 266,
                        "title": "Dishwasher",
                        "amount": 2000,
                        "included": true
                    },
                    {
                        "id": 274,
                        "title": "Garbage Disposal",
                        "amount": 2000,
                        "included": true
                    },
                    {
                        "id": 308,
                        "title": "Water Heater",
                        "amount": 2000,
                        "included": true
                    },
                    {
                        "id": 380,
                        "title": "Food Spoilage",
                        "amount": 250,
                        "included": false
                    },
                    {
                        "id": 389,
                        "title": "Appliance+ Coverage",
                        "amount": 500,
                        "included": false,
                        "info": "$500 additional coverage"
                    }
                ]
            },
            {
                "title": "Home Items",
                "devices": [
                    {
                        "id": 361,
                        "title": "Door Bells",
                        "amount": 500,
                        "included": true
                    },
                    {
                        "id": 359,
                        "title": "Ceiling Fans",
                        "amount": 500,
                        "included": true
                    },
                    {
                        "id": 360,
                        "title": "Garage Door Opener",
                        "amount": 500,
                        "included": true
                    }
                ]
            },
            {
                "title": "Premium Options",
                "devices": [
                    {
                        "id": 379,
                        "title": "Garage Hinges/Springs",
                        "amount": 100,
                        "included": false,
                    },
                    {
                        "id": 379,
                        "title": "Haul Away",
                        "amount": 100,
                        "included": false,
                    },
                    {
                        "id": 379,
                        "title": "Crane Fees",
                        "amount": 100,
                        "included": false,
                    },
                    {
                        "id": 379,
                        "title": "Permits",
                        "amount": 100,
                        "included": false,
                    },
                    {
                        "id": 379,
                        "title": "Equipment Disposals",
                        "amount": 100,
                        "included": false,
                    },
                    {
                        "id": 379,
                        "title": "HVAC Structural Modifications",
                        "amount": 100,
                        "included": false,
                    },
                    {
                        "id": 379,
                        "title": "Water Heater Replacement",
                        "amount": 100,
                        "included": false,
                    },
                    {
                        "id": 379,
                        "title": "Dishwasher Racks",
                        "amount": 100,
                        "included": false,
                        info: 'including rollers and basket'
                    },
                    {
                        "id": 379,
                        "title": "Rekey",
                        "amount": 100,
                        "included": false,
                    },
                ]
            }
        ],
        "14": [
            {
                "title": "System",
                "devices": [
                    {
                        "id": 9001,
                        "title": "AC System",
                        "amount": 5000,
                        "included": true,
                        info: 'Including cooling system (5-ton capacity/RES use), heating system and ductwork'
                    },
                    {
                        "id": 363,
                        "title": "Heating System",
                        "amount": 5000,
                        "included": true
                    },
                    {
                        "id": 9002,
                        "title": "Ductwork",
                        "amount": 5000,
                        "included": true
                    },
                    {
                        "id": 365,
                        "title": "Interior Electrical System",
                        "amount": 1500,
                        "included": true
                    },
                    {
                        "id": 367,
                        "title": "Plumbing Stoppages",
                        "amount": 1500,
                        "included": true,
                        "info": "Including toilets"
                    },
                    {
                        "id": 366,
                        "title": "Plumbing Systems",
                        "amount": 1500,
                        "included": true,
                        "info": "Including fixtures"
                    }
                ]
            },
            {
                "title": "Appliances",
                "devices": [
                    {
                        "id": 267,
                        "title": "Washer",
                        "amount": 2000,
                        "included": false
                    },
                    {
                        "id": 268,
                        "title": "Dryer",
                        "amount": 2000,
                        "included": false
                    },
                    {
                        "id": 269,
                        "title": "Refrigerator",
                        "amount": 2000,
                        "included": false
                    },
                    {
                        "id": 275,
                        "title": "Built-In Microwave",
                        "amount": 1500,
                        "included": true
                    },
                    {
                        "id": 378,
                        "title": "Kitchen Exhaust Fan",
                        "amount": 2000,
                        "included": true
                    },
                    {
                        "id": 270,
                        "title": "Oven",
                        "amount": 2000,
                        "included": true
                    },
                    {
                        "id": 266,
                        "title": "Dishwasher",
                        "amount": 2000,
                        "included": true
                    },
                    {
                        "id": 274,
                        "title": "Garbage Disposal",
                        "amount": 2000,
                        "included": true
                    },
                    {
                        "id": 308,
                        "title": "Water Heater",
                        "amount": 2000,
                        "included": true
                    },
                    {
                        "id": 380,
                        "title": "Food Spoilage",
                        "amount": 250,
                        "included": true
                    },
                    {
                        "id": 389,
                        "title": "Appliance+ Coverage",
                        "amount": 500,
                        "included": false,
                        "info": "$500 additional coverage"
                    }
                ]
            },
            {
                "title": "Home Items",
                "devices": [
                    {
                        "id": 361,
                        "title": "Door Bells",
                        "amount": 500,
                        "included": true
                    },
                    {
                        "id": 359,
                        "title": "Ceiling Fans",
                        "amount": 500,
                        "included": true
                    },
                    {
                        "id": 360,
                        "title": "Garage Door Opener",
                        "amount": 500,
                        "included": true
                    }
                ]
            },
            {
                "title": "Premium Options",
                "devices": [
                    {
                        "id": 379,
                        "title": "Garage Hinges/Springs",
                        "amount": 100,
                        "included": true,
                    },
                    {
                        "id": 379,
                        "title": "Haul Away",
                        "amount": 100,
                        "included": true,
                    },
                    {
                        "id": 379,
                        "title": "Crane Fees",
                        "amount": 100,
                        "included": true,
                    },
                    {
                        "id": 379,
                        "title": "Permits",
                        "amount": 100,
                        "included": true,
                    },
                    {
                        "id": 379,
                        "title": "Equipment Disposals",
                        "amount": 100,
                        "included": true,
                    },
                    {
                        "id": 379,
                        "title": "HVAC Structural Modifications",
                        "amount": 100,
                        "included": true,
                    },
                    {
                        "id": 379,
                        "title": "Water Heater Replacement",
                        "amount": 100,
                        "included": true,
                    },
                    {
                        "id": 379,
                        "title": "Dishwasher Racks",
                        "amount": 100,
                        "included": true,
                        info: 'including rollers and basket'
                    },
                    {
                        "id": 379,
                        "title": "Rekey",
                        "amount": 100,
                        "included": true,
                    },
                ]
            }
        ],
        "15": [
            {
                "title": "System",
                "devices": [
                    {
                        "id": 9001,
                        "title": "AC System",
                        "amount": 5000,
                        "included": true,
                        info: 'Including cooling system (5-ton capacity/RES use), heating system and ductwork'
                    },
                    {
                        "id": 363,
                        "title": "Heating System",
                        "amount": 5000,
                        "included": true
                    },
                    {
                        "id": 9002,
                        "title": "Ductwork",
                        "amount": 5000,
                        "included": true
                    },
                    {
                        "id": 365,
                        "title": "Interior Electrical System",
                        "amount": 1500,
                        "included": true
                    },
                    {
                        "id": 367,
                        "title": "Plumbing Stoppages",
                        "amount": 1500,
                        "included": true,
                        "info": "Including toilets"
                    },
                    {
                        "id": 366,
                        "title": "Plumbing Systems",
                        "amount": 1500,
                        "included": true,
                        "info": "Including fixtures"
                    }
                ]
            },
            {
                "title": "Appliances",
                "devices": [
                    {
                        "id": 267,
                        "title": "Washer",
                        "amount": 2000,
                        "included": true
                    },
                    {
                        "id": 268,
                        "title": "Dryer",
                        "amount": 2000,
                        "included": true
                    },
                    {
                        "id": 269,
                        "title": "Refrigerator",
                        "amount": 2000,
                        "included": true
                    },
                    {
                        "id": 275,
                        "title": "Built-In Microwave",
                        "amount": 1500,
                        "included": true
                    },
                    {
                        "id": 378,
                        "title": "Kitchen Exhaust Fan",
                        "amount": 2000,
                        "included": true
                    },
                    {
                        "id": 270,
                        "title": "Oven",
                        "amount": 2000,
                        "included": true
                    },
                    {
                        "id": 266,
                        "title": "Dishwasher",
                        "amount": 2000,
                        "included": true
                    },
                    {
                        "id": 274,
                        "title": "Garbage Disposal",
                        "amount": 2000,
                        "included": true
                    },
                    {
                        "id": 308,
                        "title": "Water Heater",
                        "amount": 2000,
                        "included": true
                    },
                    {
                        "id": 380,
                        "title": "Food Spoilage",
                        "amount": 250,
                        "included": true
                    },
                    {
                        "id": 389,
                        "title": "Appliance+ Coverage",
                        "amount": 500,
                        "included": true,
                        "info": "$500 additional coverage"
                    }
                ]
            },
            {
                "title": "Home Items",
                "devices": [
                    {
                        "id": 361,
                        "title": "Door Bells",
                        "amount": 500,
                        "included": true
                    },
                    {
                        "id": 359,
                        "title": "Ceiling Fans",
                        "amount": 500,
                        "included": true
                    },
                    {
                        "id": 360,
                        "title": "Garage Door Opener",
                        "amount": 500,
                        "included": true
                    }
                ]
            },
            {
                "title": "Premium Options",
                "devices": [
                    {
                        "id": 379,
                        "title": "Garage Hinges/Springs",
                        "amount": 100,
                        "included": true,
                    },
                    {
                        "id": 379,
                        "title": "Haul Away",
                        "amount": 100,
                        "included": true,
                    },
                    {
                        "id": 379,
                        "title": "Crane Fees",
                        "amount": 100,
                        "included": true,
                    },
                    {
                        "id": 379,
                        "title": "Permits",
                        "amount": 100,
                        "included": true,
                    },
                    {
                        "id": 379,
                        "title": "Equipment Disposals",
                        "amount": 100,
                        "included": true,
                    },
                    {
                        "id": 379,
                        "title": "HVAC Structural Modifications",
                        "amount": 100,
                        "included": true,
                    },
                    {
                        "id": 379,
                        "title": "Water Heater Replacement",
                        "amount": 100,
                        "included": true,
                    },
                    {
                        "id": 379,
                        "title": "Dishwasher Racks",
                        "amount": 100,
                        "included": true,
                        info: 'including rollers and basket'
                    },
                    {
                        "id": 379,
                        "title": "Rekey",
                        "amount": 100,
                        "included": true,
                    },
                ]
            }
        ]
    },
    tables: [
        {
            "tableTitle": "System",
            "tableData": [
                {
                    "headTitle": {
                        "text": "AC System",
                        info: 'Including cooling system (5-ton capacity/RES use), heating system and ductwork'
                    },
                    "colsData": [
                        {
                            "included": true
                        },
                        {
                            "included": true
                        },
                        {
                            "included": true
                        }
                    ]
                },
                {
                    "headTitle": {
                        "text": "Heating System"
                    },
                    "colsData": [
                        {
                            "included": true
                        },
                        {
                            "included": true
                        },
                        {
                            "included": true
                        }
                    ]
                },
                {
                    "headTitle": {
                        "text": "Ductwork"
                    },
                    "colsData": [
                        {
                            "included": true
                        },
                        {
                            "included": true
                        },
                        {
                            "included": true
                        }
                    ]
                },
                {
                    "headTitle": {
                        "text": "Interior Electrical System"
                    },
                    "colsData": [
                        {
                            "included": true
                        },
                        {
                            "included": true
                        },
                        {
                            "included": true
                        }
                    ]
                },
                {
                    "headTitle": {
                        "text": "Plumbing Stoppages",
                        "info": "Including toilets"
                    },
                    "colsData": [
                        {
                            "included": true
                        },
                        {
                            "included": true
                        },
                        {
                            "included": true
                        }
                    ]
                },
                {
                    "headTitle": {
                        "text": "Plumbing Systems",
                        "info": "Including fixtures"
                    },
                    "colsData": [
                        {
                            "included": true
                        },
                        {
                            "included": true
                        },
                        {
                            "included": true
                        }
                    ]
                }
            ]
        },
        {
            "tableTitle": "Appliances",
            "tableData": [
                {
                    "headTitle": {
                        "text": "Washer"
                    },
                    "colsData": [
                        {
                            "included": false
                        },
                        {
                            "included": false
                        },
                        {
                            "included": true
                        }
                    ]
                },
                {
                    "headTitle": {
                        "text": "Dryer"
                    },
                    "colsData": [
                        {
                            "included": false
                        },
                        {
                            "included": false
                        },
                        {
                            "included": true
                        }
                    ]
                },
                {
                    "headTitle": {
                        "text": "Refrigerator"
                    },
                    "colsData": [
                        {
                            "included": false
                        },
                        {
                            "included": false
                        },
                        {
                            "included": true
                        }
                    ]
                },
                {
                    "headTitle": {
                        "text": "Built-In Microwave"
                    },
                    "colsData": [
                        {
                            "included": true
                        },
                        {
                            "included": true
                        },
                        {
                            "included": true
                        }
                    ]
                },
                {
                    "headTitle": {
                        "text": "Oven"
                    },
                    "colsData": [
                        {
                            "included": true
                        },
                        {
                            "included": true
                        },
                        {
                            "included": true
                        }
                    ]
                },
                {
                    "headTitle": {
                        "text": "Kitchen Exhaust Fan"
                    },
                    "colsData": [
                        {
                            "included": true
                        },
                        {
                            "included": true
                        },
                        {
                            "included": true
                        }
                    ]
                },
                {
                    "headTitle": {
                        "text": "Dishwasher"
                    },
                    "colsData": [
                        {
                            "included": true
                        },
                        {
                            "included": true
                        },
                        {
                            "included": true
                        }
                    ]
                },
                {
                    "headTitle": {
                        "text": "Garbage Disposal"
                    },
                    "colsData": [
                        {
                            "included": true
                        },
                        {
                            "included": true
                        },
                        {
                            "included": true
                        }
                    ]
                },
                {
                    "headTitle": {
                        "text": "Water Heater"
                    },
                    "colsData": [
                        {
                            "included": true
                        },
                        {
                            "included": true
                        },
                        {
                            "included": true
                        }
                    ]
                },
                {
                    "headTitle": {
                        "text": "Food Spoilage"
                    },
                    "colsData": [
                        {
                            "included": false
                        },
                        {
                            "included": true
                        },
                        {
                            "included": true
                        }
                    ]
                },
                {
                    "headTitle": {
                        "text": "Appliance+ Coverage",
                        "info": "$500 additional coverage"
                    },
                    "colsData": [
                        {
                            "included": false
                        },
                        {
                            "included": false
                        },
                        {
                            "included": true
                        }
                    ]
                }
            ]
        },
        {
            "tableTitle": "Home Items",
            "tableData": [
                {
                    "headTitle": {
                        "text": "Door Bells"
                    },
                    "colsData": [
                        {
                            "included": true
                        },
                        {
                            "included": true
                        },
                        {
                            "included": true
                        }
                    ]
                },
                {
                    "headTitle": {
                        "text": "Ceiling Fans"
                    },
                    "colsData": [
                        {
                            "included": true
                        },
                        {
                            "included": true
                        },
                        {
                            "included": true
                        }
                    ]
                },
                {
                    "headTitle": {
                        "text": "Garage Door Opener"
                    },
                    "colsData": [
                        {
                            "included": true
                        },
                        {
                            "included": true
                        },
                        {
                            "included": true
                        }
                    ]
                }
            ]
        },
        {
            "tableTitle": "Premium Options",
            "tableData": [
                {
                    "headTitle": {
                        "text": "Garage Hinges/Springs",
                        "info": "Garage (hinges and springs) haul away crane fees permits modification recapture and reclamation equipment disposal structural modifications for HVAC and water heater replacements dishwasher extras (racks, rollers, and baskets) rekeys"
                    },
                    "colsData": [
                        {
                            "included": false
                        },
                        {
                            "included": true
                        },
                        {
                            "included": true
                        }
                    ]
                },
                {
                    "headTitle": {
                        "text": "Haul Away"
                    },
                    "colsData": [
                        {
                            "included": false
                        },
                        {
                            "included": true
                        },
                        {
                            "included": true
                        }
                    ]
                },
                {
                    "headTitle": {
                        "text": "Crane Fees"
                    },
                    "colsData": [
                        {
                            "included": false
                        },
                        {
                            "included": true
                        },
                        {
                            "included": true
                        }
                    ]
                },
                {
                    "headTitle": {
                        "text": "Permits"
                    },
                    "colsData": [
                        {
                            "included": false
                        },
                        {
                            "included": true
                        },
                        {
                            "included": true
                        }
                    ]
                },
                {
                    "headTitle": {
                        "text": "Recapture/Reclamation"
                    },
                    "colsData": [
                        {
                            "included": false
                        },
                        {
                            "included": true
                        },
                        {
                            "included": true
                        }
                    ]
                },
                {
                    "headTitle": {
                        "text": "Equipment Disposals"
                    },
                    "colsData": [
                        {
                            "included": false
                        },
                        {
                            "included": true
                        },
                        {
                            "included": true
                        }
                    ]
                },
                {
                    "headTitle": {
                        "text": "HVAC Structural Modifications"
                    },
                    "colsData": [
                        {
                            "included": false
                        },
                        {
                            "included": true
                        },
                        {
                            "included": true
                        }
                    ]
                },
                {
                    "headTitle": {
                        "text": "Water Heater Replacement"
                    },
                    "colsData": [
                        {
                            "included": false
                        },
                        {
                            "included": true
                        },
                        {
                            "included": true
                        }
                    ]
                },
                {
                    "headTitle": {
                        "text": "Dishwasher Racks",
                        info: 'including rollers and basket'
                    },
                    "colsData": [
                        {
                            "included": false
                        },
                        {
                            "included": true
                        },
                        {
                            "included": true
                        }
                    ]
                },
                {
                    "headTitle": {
                        "text": "Rekey"
                    },
                    "colsData": [
                        {
                            "included": false
                        },
                        {
                            "included": true
                        },
                        {
                            "included": true
                        }
                    ]
                },
            ]
        }
    ],
    plansAddons: [
        {
            "id": 2,
            "title": "Additional Appliances",
            "list": [
                {
                    "text": {
                        "content": "Additional Fridge"
                    },
                    "price": {
                        "paymentTerm": "y",
                        "value": 60
                    }
                },
                {
                    "text": {
                        "content": "Additional Washer"
                    },
                    "price": {
                        "paymentTerm": "y",
                        "value": 60
                    }
                },
                {
                    "text": {
                        "content": "Additional Dryer"
                    },
                    "price": {
                        "paymentTerm": "y",
                        "value": 20
                    }
                },
                {
                    "text": {
                        "content": "Freestanding Freezer"
                    },
                    "price": {
                        "paymentTerm": "y",
                        "value": 50
                    }
                },
                {
                    "text": {
                        "content": "Freestanding Wine chiller"
                    },
                    "price": {
                        "paymentTerm": "y",
                        "value": 50
                    }
                },
                {
                    "text": {
                        "content": "Freestanding Ice maker"
                    },
                    "price": {
                        "paymentTerm": "y",
                        "value": 50
                    }
                }
            ]
        },
        {
            "id": 3,
            "title": "Miscellaneous",
            "list": [
                {
                    "text": {
                        "content": "Sump Pump"
                    },
                    "price": {
                        "paymentTerm": "y",
                        "value": 60
                    }
                },
                {
                    "text": {
                        "content": "Well Pump"
                    },
                    "price": {
                        "paymentTerm": "y",
                        "value": 95
                    }
                },
                {
                    "text": {
                        "content": "Water Softener"
                    },
                    "price": {
                        "paymentTerm": "y",
                        "value": 40
                    }
                },
                {
                    "text": {
                        "content": "Septic Pump"
                    },
                    "price": {
                        "paymentTerm": "y",
                        "value": 95
                    }
                },
                {
                    "text": {
                        "content": "Central vacuum"
                    },
                    "price": {
                        "paymentTerm": "y",
                        "value": 20
                    }
                }
            ]
        },
        {
            "id": 4,
            "title": "External Lines",
            "list": [
                {
                    "text": {
                        "content": "External Sewer Lines"
                    },
                    "price": {
                        "paymentTerm": "y",
                        "value": 70
                    }
                },
                {
                    "text": {
                        "content": "External Water Lines"
                    },
                    "price": {
                        "paymentTerm": "y",
                        "value": 55
                    }
                },
                {
                    "text": {
                        "content": "External Electric Lines"
                    },
                    "price": {
                        "paymentTerm": "y",
                        "value": 20
                    }
                },
                {
                    "text": {
                        "content": "External Gas Lines"
                    },
                    "price": {
                        "paymentTerm": "y",
                        "value": 55
                    }
                },
                {
                    "text": {
                        "content": "External Lines Bundle"
                    },
                    "price": {
                        "paymentTerm": "y",
                        "value": 175
                    }
                }
            ]
        },
        {
            "id": 1,
            "title": "Pool/Pump",
            "list": [
                {
                    "text": {
                        "content": "Swimming Pool & Spa",
                        "info": "including saltwater"
                    },
                    "price": {
                        "paymentTerm": "y",
                        "value": 150
                    }
                },
                {
                    "text": {
                        "content": "Additional Pool Pump"
                    },
                    "price": {
                        "paymentTerm": "y",
                        "value": 60
                    }
                }
            ]
        }
    ]
}