export default {
    generateDevicesCategoriesPerPlan(coverageDevices: any, plansToCategorize: number[], categoriesMap: any, baseElement: number) {
        if(!coverageDevices) return;
        const baseElementDevicesMap = coverageDevices[baseElement].reduce((acc: any, device: any) => {
            acc[device.device_id] = device;
            return acc;
        }, {})
        const coveragePerPlanMap = plansToCategorize.reduce((acc: any, next: any) => {
            acc[next] = generateDevicesArray({id: next, coverageDevices, categoriesMap, baseElementDevicesMap });
            return acc;
        }, {})
        return coveragePerPlanMap;
    },
    getDevicesGroups: ({staticPlans, coverageDevices, devicesAdditionalInfoMap}: any) => {
        const additionalInfo: any = Object.assign({}, devicesAdditionalInfoMap)
        return staticPlans.reduce((acc: any, planId: number) => {
            acc[planId] = Object.keys(coverageDevices[planId]).map((section) => {
                return {
                    title: section,
                    devices: coverageDevices[planId][section].map((device: any) => {
                        const updatedDevice = {...device};
                        if(additionalInfo[device.id]) updatedDevice.info = additionalInfo[device.id];
                        return updatedDevice;
                    })
                }
            })
            return acc;
        }, {})
    },
    buildTableData: ({coverageDevices, staticPlans, basePlanId, devicesAdditionalInfoMap}: any) => {
        const additionalInfo: any = Object.assign({}, devicesAdditionalInfoMap)
        const categories = Object.keys(coverageDevices[staticPlans[0]]);
        const tables = categories.map(category => {
            const devices = coverageDevices[basePlanId][category];
            let idDeviceMap: any = {};
            staticPlans
                .filter((planId: number) => planId !== basePlanId)
                .forEach((planId: number) => {
                    const deviceForPlan = coverageDevices[planId][category].reduce((acc: any, device: any) => {
                        acc[`${planId}_${device.id}`] = device;
                        return acc;
                    }, {})
                    idDeviceMap = Object.assign(idDeviceMap, deviceForPlan)
                })
            const tableData = devices.map((device: any) => {
                const colsData = staticPlans.map((planId: number) => {
                    const included = planId === basePlanId || (idDeviceMap[`${planId}_${device.id}`] && idDeviceMap[`${planId}_${device.id}`].included);
                    return { included };
                })
                return {
                    headTitle: { text: device.title, info: additionalInfo[device.id] },
                    colsData
                }
            })
            return {
                tableTitle: category,
                tableData
            }
        })
        return tables;
    },
    buildPerksTable: ({iconsMap, basePlanPerksList, plans}: any) => {
        let perksTable: any = [];
        basePlanPerksList.forEach((planPerk: any) => {
            const PerkRowObject: any = {
                headTitle: { text: planPerk.text },
                colsData: []
            }
            Object.keys(plans).forEach((planId) => {
                const included = plans[planId].perks.list.some((perk: any) => perk.id === planPerk.id);
                const includedObject: any = {
                    included
                }
                if(included) includedObject.includedCheckIcon = iconsMap[planId];
                PerkRowObject.colsData.push((includedObject))
            })
            perksTable.push(PerkRowObject);
        })
        return perksTable;
    },
    generatePlanAddonsByCategory: (planAddons: any, addonsAdditionalInfo: any) => {
        const categoriesObj: any = {}
        planAddons.forEach((addon: any) => {
            const bundleId = addon.bundle.id;
            if(!categoriesObj[bundleId]) {
                categoriesObj[bundleId] = {
                    id: addon.bundle.id,
                    title: addon.bundle.title, 
                    list: []
                }
            }
            categoriesObj[bundleId].list.push({
                text: {
                    content: addon.title,
                    info: addonsAdditionalInfo[addon.service_id]
                },
                price: {
                    paymentTerm: 'y',
                    value: addon.price
                }
            })
        })
        return categoriesObj;
    }
}

function generateDevicesArray({id, coverageDevices, categoriesMap, baseElementDevicesMap}: any) {
    const planDevicesMap: any = {};
    const categories = Object.keys(categoriesMap);
    coverageDevices[id].forEach((device: any) => { planDevicesMap[device.device_id] = 1 });
    const devicesObj = categories.reduce((acc: any, category: string) => {
        acc[category] = [];
        return acc;
    }, {})
    categories.forEach((category) => {
        categoriesMap[category].forEach((deviceId: number) => {
            const deviceObj = {
                id: deviceId,
                title: baseElementDevicesMap[deviceId].device_title,
                amount: baseElementDevicesMap[deviceId].max_amount,
                included: !!(planDevicesMap[deviceId])
            }
            devicesObj[category].push(deviceObj);
        })
    })
    return devicesObj;
}