import _ from 'lodash';
import { fields as FieldConstants } from '@puls/puls-common-constants';
import { funnelsActions as actionConstants} from '@puls/puls-common-constants';
import { userDetailsConstants } from '@puls/puls-common-constants';
import { funnelUtilsConstants as utilsConstants} from '@puls/puls-common-constants';
import { inputValidators } from '../Services/inputsValidators';
import { EMAIL_UNIQUE_STRATEGIES } from '@puls/puls-common-constants';

const getPlanInitialState = () => {
    return {
        id: 0,
        title: '',
        benefits: []
    }
}

const getSubscriptionInitialState = () => {
    return {
        account_id: 0,
        account_name: ''
    }
}

const getReferralInitialState = () => {
    return {
        credits: 0,
        is_valid: false
    }
}

const initialUserFields = {
    [FieldConstants.FIRST_NAME] : '',
    [FieldConstants.LAST_NAME] : '',
    [FieldConstants.EMAIL] : '',
    [FieldConstants.PHONE] : '',
    [FieldConstants.COMPANY_NAME]: '',
    isValid : false,
}

const realEstateTitleValidatonFields = [
    FieldConstants.EMAIL,
    FieldConstants.PHONE,
    FieldConstants.COMPANY_NAME
]

const commonValidationFields = Object.keys(userDetailsConstants.inputs).filter((obj) => obj !== FieldConstants.COMPANY_NAME);

export let initialState = {
    ...initialUserFields,
    ...userDetailsConstants,
    isUserSubscribed: false,
    exisiting_contact_id: '',
    exisiting_account_id: '',
    partner: null,
    contact_loaded_from_server: false,
    plan: getPlanInitialState(),
    subscription: getSubscriptionInitialState(),
    referral: getReferralInitialState(),
    isBenefitsModalOpen: false,
    isValidatingSubscription: false,
    showNewUpsell: false,
    auth: null,
    isUniqueStrategy: EMAIL_UNIQUE_STRATEGIES.ALWAYS_TRUE,
    auxiliaries: {
        [utilsConstants.REAL_ESTATE_USERS.AGENT]: {
            ...initialUserFields,
            isUniqueStrategy: EMAIL_UNIQUE_STRATEGIES.ALWAYS_TRUE,
        },
        [utilsConstants.REAL_ESTATE_USERS.TITLE]: {
            ...initialUserFields,
            isUniqueStrategy: EMAIL_UNIQUE_STRATEGIES.CHECK_UNIQUNESS,
        }
    }
};

export default (state = initialState, action) => {
    let newState = null;
    switch(action.type){
        case (actionConstants.INIT_APP):
            newState = _.cloneDeep(initialState);
            newState.auth = action.payload.auth;
            return newState;
        case (actionConstants.UPDATE_USER_DETAILS_FIELD):
            newState = _.cloneDeep(state);
            let isValid = true;
            const userToChange = action.payload.auxiliary ? newState.auxiliaries[action.payload.auxiliary] : newState;
            userToChange[action.payload.field] = action.payload.value;
            const validators = action.payload.auxiliary && (action.payload.auxiliary === utilsConstants.REAL_ESTATE_USERS.TITLE) ? realEstateTitleValidatonFields : commonValidationFields;
            validators.forEach((field)=>{
                isValid = isValid && inputValidators[newState.inputs[field].validateStrategy](userToChange[field]);
            });
            userToChange.isValid = isValid;
            return newState;
        case (actionConstants.UPDATE_SUBSCRIPTION_STATUS):
            newState = _.cloneDeep(state);
            return setSubscriptionDetails(action, newState);
        case (actionConstants.UPDATE_EXISTING_CONTACT_ID):
            newState = _.cloneDeep(state);
            newState.exisiting_contact_id = action.payload.id;
            return newState;
        case (actionConstants.UPDATE_EXISTING_ACCOUNT_ID):
            newState = _.cloneDeep(state);
            newState.exisiting_account_id = action.payload.id;
            return newState;
        case (actionConstants.UPDATE_EXISTING_CONTACT_ID_DATA):
            let newState = _.cloneDeep(state);
            return setExistingUserDetails(action.payload, newState);
        case (actionConstants.UPDATE_SUBSCRIPTION_VALIDATION_INDICATOR):
            newState = _.cloneDeep(state);
            newState.isValidatingSubscription = action.payload;
            if(action.clear_benefits_data){
                newState.plan.benefits = [];
            }
            return newState;
        case (actionConstants.TOGGEL_BENEFITS_MODAL):
            newState = _.cloneDeep(state);
            newState.isBenefitsModalOpen = !newState.isBenefitsModalOpen;
            return newState;
        case (actionConstants.CHANGE_SELECTED_BENEFIT):
            newState = _.cloneDeep(state);
            newState.plan.benefits.forEach((a) => {
                a.selected = false;
            })
            newState.plan.benefits[action.payload].selected = true;
            return newState;
        case (actionConstants.SET_SHOW_UPSELL):
            newState = _.cloneDeep(state);
            newState.showNewUpsell = action.payload.showNew;
            return newState;
        case (actionConstants.SET_FROM_USER_EMAIL): 
            newState = _.cloneDeep(state);
            newState = setExistingUserDetails(action.payload, newState)
            newState = setSubscriptionDetails(action, newState)
            return newState;
        default:
    
    }
    return state
}


const setExistingUserDetails = (payload, newState) => {
    const { first_name, last_name, email, phone } = payload;
    newState[FieldConstants.FIRST_NAME] = first_name || '';
    newState[FieldConstants.LAST_NAME] = last_name || '';
    newState[FieldConstants.EMAIL] = email || '';
    newState[FieldConstants.PHONE] = phone || '';
    // since all the fileds comes from the server we can assume they are valid so only check if the field exsits for validity
    newState.isValid = !!(first_name && last_name && email && phone);
    newState.isUserSubscribed = payload.account && payload.account.subscription && payload.account.subscription.is_active;
    newState.contact_loaded_from_server = true;
    return newState;
}

const setSubscriptionDetails = (action, newState) => {
    const referralCode = action.referral_code;
    newState.isUserSubscribed = action.payload.is_subscribed;
    newState.plan = getPlanInitialState();
    newState.subscription = getSubscriptionInitialState();
    newState.referral = getReferralInitialState();
    newState.partner = null;
    newState.isValidatingSubscription = false;

    if(action.payload.referral_credits) {
        newState.referral.credits = action.payload.referral_credits;
        newState.isBenefitsModalOpen = true;
    }

    if(action.payload.is_referral_code_valid) newState.referral.is_valid = action.payload.is_referral_code_valid;

    if(referralCode && !newState.referral.is_valid){
        newState.isBenefitsModalOpen = true;
    }

    if(action.payload.partner){
        newState.partner = action.payload.partner;
        newState.isBenefitsModalOpen = true;
    }

    // save the subscription account id
    if(action.payload.subscription){
        newState.subscription.account_id = action.payload.subscription.account_id;
        newState.subscription.account_name = action.payload.subscription.first_name;
    }

    if(action.payload.plan) {
        newState.plan.id = action.payload.plan.plan_id;
        newState.plan.title = action.payload.plan.title;
        var isBenefitSelected = false;

        if(!action.payload.skip_benefit_selection){
            action.payload.plan.benefits.forEach(function(benefit){
                if(benefit.total_saving <= 0) benefit.reach_limit = true;
            })
            // first try to select the default
            // replace the map to somthing else
            newState.plan.benefits = action.payload.plan.benefits.map((benefit) => {
                if(!isBenefitSelected && !benefit.reach_limit && benefit.is_default){
                    benefit.selected = true;
                    isBenefitSelected = true;
                }
                return benefit;
            });
    
            // if there is no benefit selected yet try to selected the first valid
            // replace the map to somthing else
            if(!isBenefitSelected) {
                newState.plan.benefits = action.payload.plan.benefits.map((benefit) => {
                    if(!isBenefitSelected && !benefit.reach_limit){
                        benefit.selected = true;
                        isBenefitSelected = true;
                    }
                    return benefit;
                });
            }
    
            // last chance to make at least one benefit selected = true
            if (newState.plan.benefits.length && newState.plan.benefits.every(bene => !bene.selected)) {
                newState.plan.benefits[0].selected = true;
            }
        }
        
        if(!action.payload.hide_modal) newState.isBenefitsModalOpen = true;
    }
    return newState;
}
