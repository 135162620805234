const ABTestConstants = {
    CROSSED_PRICE: 'ab_test_crossed_price',
    PAYMENT: 'ab_test_payment',
    TIME_SLOTS_WORKING_HOURS: 'ab_test_time_slots_working_hours',
    IMAGE_UPLOAD: 'ab_test_upload_images',
    UPSELL_MEMBERSHIP: 'ab_test_upsell_membership',
    HOURLY_PRICES: 'ab_test_hourly_prices',
    ABOVE_FIREPALCE: 'ab_test_tv_above_fireplace',
    EXPEDITE_TIME: 'ab_test_expedite_time',
    HANDYMAN_PAYMENT: 'ab_test_hdm_payment',
    WORK_ORDER_ALTERNATIVE: 'ab_test_work_order',
    WORK_ORDER_ALTERNATIVE_APR: 'ab_test_work_order_apr',
    BLENDER_PRICE_ESTIMATION: 'ab_test_blender_pricing',
    APR_FUNNEL_IMPROVEMENTS: 'ab_test_apr_funnel_improvements',
    APR_LP_AB_TEST: 'ab_test_apr_landing_page',
    MULTIPLE_TIME_SLOTS: 'ab_test_multiple_time_slots'
}

export default ABTestConstants;

export const abTestNamesMap ={
    [ABTestConstants.CROSSED_PRICE] : 'HDM funnel no discount display vs. discount display (10/24/19)',
    [ABTestConstants.PAYMENT] : 'Payment AB Test 90% / 10%',
    [ABTestConstants.TIME_SLOTS_WORKING_HOURS] : 'All vertical - Working hours 9-19 (12/08/19)',
    [ABTestConstants.IMAGE_UPLOAD]: 'Upload Images (HDM)',
    [ABTestConstants.UPSELL_MEMBERSHIP] : 'Membership upsell test - (1/25/22)',
    [ABTestConstants.HOURLY_PRICES] : 'Handyman hourly price (TBD)',
    [ABTestConstants.ABOVE_FIREPALCE] : 'TVI Above Fireplace Service (2/1/23)',
    [ABTestConstants.EXPEDITE_TIME] : 'Expedite service $20 (5/16/23)',
    [ABTestConstants.HANDYMAN_PAYMENT]: 'Handyman payment AB Test (9/13/23)',
    [ABTestConstants.WORK_ORDER_ALTERNATIVE]: 'Work Order Test (Thumbtack Alternative)',
    [ABTestConstants.WORK_ORDER_ALTERNATIVE_APR]: 'Work Order Test APR (Thumbtack Alternative)',
    [ABTestConstants.BLENDER_PRICE_ESTIMATION]: 'Blender pricing estimation (1/17/24)',
    [ABTestConstants.APR_FUNNEL_IMPROVEMENTS]: 'Apr funnel improvements (1/17/24)',
    [ABTestConstants.MULTIPLE_TIME_SLOTS]: 'Funnel multiple time slots (07/03/24)'
}