import utils from "./utils";
import { INPUTS_VALIDATORS_STRATEGIES } from '@puls/puls-common-constants'
const MAX_CHAR_LENGTH = 120;
const MAX_ADDRESS_CHAR_LENGTH = 255;

const { TEXT, EMAIL, PHONE, ADDRESS, ADDRESS_GOOGLE } = INPUTS_VALIDATORS_STRATEGIES;

export const inputValidators = {
    [EMAIL]: (value) => /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(value) && value.length <= MAX_CHAR_LENGTH,
    [PHONE]: (value) => {
        return (
            /\(?([0-9]{3})\)?([ ]?)([0-9]{3})?([-]?)([0-9]{4})/.test(value)
            &&
            value.replace(/\D/g,'').length === 10
        )
    },
    [TEXT]: (value) => value.trim().length <= MAX_CHAR_LENGTH && value.trim().length > 0,
    [ADDRESS]: (value) => value.trim().length <= MAX_ADDRESS_CHAR_LENGTH,
    [ADDRESS_GOOGLE]: (value, googleData) => {
        let formatted_address = ( googleData && googleData.formatted_address && googleData.formatted_address ) || null;
        let postal_code = (googleData && googleData.address_components && utils.getPostalCode(googleData.address_components) && googleData.isGoogleZipCodeValid) || null;
        return (value && value.trim().length <= MAX_ADDRESS_CHAR_LENGTH && value.trim().length > 0) && (formatted_address === value) && postal_code
    },
}