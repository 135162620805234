import {funnelQuestionTypes as QuestionTypes} from '@puls/puls-common-constants'

export default (selectedService) => {
    var services = [];
    selectedService.questions.forEach((question)=> {
        switch (question.type) {
            case QuestionTypes.STEPPER_SELECTION:
                question.options.forEach((option) => {
                    switch (option.servicesLogic) {
                        case 'thermostat':
                            new Array(option.count).fill(0).forEach((_, index) => {
                                if(!index) return services.push(95);
                                services.push(96);
                            })
                            break;
                        case 'smarthome':
                            new Array(option.count).fill(0).forEach((_, index) => {
                                if(!index) return services.push(1647);
                                services.push(1648);
                            })
                            // if(option.count === 1) services.push(92);
                            // else if(option.count >= 2 && option.count <=3) services.push(93);
                            // else if( option.count > 3) services.push(94);
                            // else {}
                            break;
                        case 'smarthome-camera':
                            if(!option.count){
                                // nothing
                            }
                            else if(option.count <= 2) {
                                services.push(1647);
                            } else {
                                new Array(option.count).fill(0).forEach((_, index) => {
                                    if(!index) return;
                                    if(index === 1) return services.push(1647);
                                    services.push(1648);
                                })
                            }
                            break;
                        case 'smarthome-wifi-connection':
                            new Array(option.count).fill(0).forEach((_, index) => {
                                if(!index) return services.push(1650)
                                services.push(1648);
                            })
                            break;
                        case 'smarthome-wifi-extention':
                            new Array(option.count).fill(0).forEach((_, index) => {
                                if(!index) return services.push(1651)
                                services.push(1648);
                            })
                            break;
                        default:

                    }
                })
                break;
            case QuestionTypes.SINGLE_ANSWER:
                question.options.forEach((option) => {
                    if(option.selected && option.withService) services.push(option.withService)
                })
                break;

            default:

        }
    })

    return services
}
