const PHONE_REPAIR_ID = 1
const TV_ID = 2
const SMART_HOME_ID = 3
const HELPER_ID = 4
const ANTENNAS_ID = 5
const COMPUTER_ID = 6
const GARAGE_DOORS_ID = 7
const APPLIANCES_ID = 8
const PLUMBING_ID = 9
const HANDYMAN_ID = 10
const DISINFECTION_ID = 11
const HVAC_ID = 12
const HOME_CLEANING_ID = 13;
const LANDSCAPING_ID = 14;
const LOCKSMITH_REKEY_ID = 15;
const ELECTRICAL_ID = 16;
export default {
	IDS: {
		PHONE_REPAIR: PHONE_REPAIR_ID,
		TV: TV_ID,
		SMART_HOME: SMART_HOME_ID,
		HELPER: HELPER_ID,
		ANTENNAS: ANTENNAS_ID,
		COMPUTER: COMPUTER_ID,
		GARAGE_DOORS: GARAGE_DOORS_ID,
		APPLIANCES: APPLIANCES_ID,
		PLUMBING: PLUMBING_ID,
		HANDYMAN: HANDYMAN_ID,
		DISINFECTION: DISINFECTION_ID,
		HVAC: HVAC_ID,
		HOME_CLEANING: HOME_CLEANING_ID,
		LANDSCAPING: LANDSCAPING_ID,
		LOCKSMITH_REKEY: LOCKSMITH_REKEY_ID,
		ELECTRICAL: ELECTRICAL_ID
	},
	NAMES: {
		[PHONE_REPAIR_ID]: "Phone Repair",
		[TV_ID]:"TV Mounting" ,
		[SMART_HOME_ID]: "Smart Home",
		[HELPER_ID]: "Helper",
		[ANTENNAS_ID]: "Antennas",
		[COMPUTER_ID]: "Computer",
		[GARAGE_DOORS_ID]: "Garge Doors",
		[APPLIANCES_ID]: "Home Appliances",
		[PLUMBING_ID]: "Plumbing",
		[HANDYMAN_ID]: "Handyman",
		[DISINFECTION_ID]: "Disinfection Services",
		[HVAC_ID]: 'HVAC',
		[HOME_CLEANING_ID]: 'Home Cleaning',
		[LANDSCAPING_ID]: 'Landscaping',
		[LOCKSMITH_REKEY_ID]: 'Locksmith/Rekey',
		[ELECTRICAL_ID]: 'Electrical' 
	}
}
