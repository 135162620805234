export default{
    ADDRESS : 'address',
    ADDITIONAL_ADDRESS : 'additional',
    OPTIONAL : 'optional',
    FIRST_NAME : 'firstname',
    LAST_NAME : 'lastname',
    EMAIL : 'email',
    PHONE : 'phone',
    COMPANY_NAME: 'company'
}
