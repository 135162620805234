import { fields as FieldConstants} from '@puls/puls-common-constants';
import { EMAIL_UNIQUE_STRATEGIES } from '@puls/puls-common-constants'

const { ALWAYS_TRUE, CHECK_UNIQUNESS } = EMAIL_UNIQUE_STRATEGIES;

export const uniqueValidators = {
    [ALWAYS_TRUE]: () => true,
    [CHECK_UNIQUNESS]: (userdetails, emailToCheck) => {
        const { auxiliaries } = userdetails;
        const emailsMap = {};
        Object.keys(auxiliaries).forEach((aux) => {
            if(auxiliaries[aux][FieldConstants.EMAIL]) {
                emailsMap[auxiliaries[aux][FieldConstants.EMAIL]] = (emailsMap[auxiliaries[aux][FieldConstants.EMAIL]] || 0) + 1;
            }
        })
        if(userdetails[FieldConstants.EMAIL]) {
            emailsMap[userdetails[FieldConstants.EMAIL]] = (emailsMap[userdetails[FieldConstants.EMAIL]] || 0) +1;
        }
        return emailsMap[emailToCheck] === 1;
    }
}