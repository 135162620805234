interface RUM_Config {
    service_name: string,
    server_url: string,
    agent_src: string,
    environment: string
}

type Config = RUM_Config | null;

export default {
    start: (config: Config  = null) => {
        try {
            const script = document.createElement('script');
            const rumConfig: Config = config || resolveConfigFromDOM();
            if(!rumConfig) return null;
            script.src = rumConfig.agent_src;
            script.onload = function () {
                const _w: any = window || {};
                _w.elasticApm.init({
                    serviceName: rumConfig.service_name,
                    serverUrl: rumConfig.server_url,
                    environment: rumConfig.environment || 'development'
                })
            }

            document.body.appendChild(script);
        } catch (error) {
            console.error(error);
        }
    }
}

function resolveConfigFromDOM(): Config {
    const cnfElem: string | undefined = document.getElementById("client-rum-config")?.innerText;
    if(!cnfElem) return null;
    return JSON.parse(cnfElem);
}