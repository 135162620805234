import timeSlotsUtils from "../../time-slots";
import { 
    GetTimeActionOptions, 
    GetTimesActionDispatch, 
    TimeState, 
    TimeSlotsMap, 
    ToggleSelectedTimeActionDispatch, 
    ToggleSelectedDateActionDispatch, 
    SelectionLimits, 
    ClearTimeSlotsAction, 
    UpdateSelectionLimitsAction, 
    SetTimeSlotsErrorAction, 
    EActions, 
    SelectedTimeSlot,
    UpdatePreSelectedTimes
} from '../../models/time';
import { BeSlot, Date } from "@puls/puls-common-constants";

const timesActions = {
    getTimes: ({ prepareData, transformData, onDone }: GetTimeActionOptions) => async (dispatch: GetTimesActionDispatch, getState: Function) => {
        dispatch({
            type: EActions.SET_LOADING_TIME_SLOTS,
            payload: {
                isLoading: true
            }
        })
        try {
            const slots = await prepareData(dispatch, getState);
            const transformedData: TimeSlotsMap = transformData(slots);
            dispatch({
                type: EActions.SET_TIME_SLOTS,
                payload: {
                    slots: transformedData
                }
            })
            onDone && onDone(dispatch, getState);
        } catch (err: any) {
            dispatch({
                type: EActions.SET_TIME_SLOTS_ERROR,
                payload: {
                    message: err.message
                }
            })
        } finally {
            dispatch({
                type: EActions.SET_LOADING_TIME_SLOTS,
                payload: {
                    isLoading: false
                }
            })
        }
    },
    toggleSelectedTime: (selectedSlot: SelectedTimeSlot) => (dispatch: ToggleSelectedTimeActionDispatch, getState: Function) => {
        const state = getState();
        const { selectionLimits }: TimeState = state.time;
        const isSingleSelection = timeSlotsUtils.isSingleSelection(selectionLimits);
        if(isSingleSelection && selectedSlot.time.selected) return;
        dispatch({
            type: EActions.TOGGLE_TIME,
            payload: {
                selectedSlot
            }
        })
    },
    toggleSelectedDate: (selectedDate: Date) => (dispatch: ToggleSelectedDateActionDispatch, getState: Function) => {
        const state = getState();
        const { selectionLimits }: TimeState = state.time;
        const isSingleSelection = timeSlotsUtils.isSingleSelection(selectionLimits);
        if(isSingleSelection && selectedDate.selected) return;
        dispatch({
            type: EActions.TOGGLE_DATE,
            payload: {
                selectedDate
            }
        })
    },
    clearTimeSlots: (): ClearTimeSlotsAction => {
        return {
            type: EActions.CLEAR_TIME_SLOTS
        }
	},
    updateSelectionLimits: (data: SelectionLimits): UpdateSelectionLimitsAction | undefined => {
        const { date, time } = data;
        if(!date || !time) return;
        return {
            type: EActions.UPDATE_SELECTION_LIMITS,
            payload: data
        }
    },
    updatePreSelectedTimes: (selectedSlots: BeSlot[]): UpdatePreSelectedTimes => {
        return {
            type: EActions.UPDATE_PRE_SELECTED_TIMES,
            payload: {
                selectedSlots
            }
        }
    },
    setError: (message: string): SetTimeSlotsErrorAction => {
        return {
            type: EActions.SET_TIME_SLOTS_ERROR,
            payload: { 
                message 
            }
        }
    }
}
export default timesActions;