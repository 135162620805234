const SINGLE_ANSWER = 'SINGLE_ANSWER';
const MULTIPLE_ANSWER = 'MULTIPLE_ANSWER';
const STEPPER_SELECTION = 'STEPPER_SELECTION';
const FREE_TEXT_ANSWER = 'FREE_TEXT_ANSWER';
const AUTOCOMPLETE_SELECTION = 'AUTOCOMPLETE_SELECTION';
const MINIMAL_AUTOCOMPLETE_SELECTION = 'MINIMAL_AUTOCOMPLETE_SELECTION';
const MULTIPLE_ANSWER_WITH_SECTIONS = 'MULTIPLE_ANSWER_WITH_SECTIONS';
const FORM_FORM = 'FORM_FORM';

export default {
    [SINGLE_ANSWER]: SINGLE_ANSWER,
    [MULTIPLE_ANSWER]: MULTIPLE_ANSWER,
    [STEPPER_SELECTION]: STEPPER_SELECTION,
    [FREE_TEXT_ANSWER]:  FREE_TEXT_ANSWER,
    [AUTOCOMPLETE_SELECTION]:  AUTOCOMPLETE_SELECTION,
    [MINIMAL_AUTOCOMPLETE_SELECTION]: MINIMAL_AUTOCOMPLETE_SELECTION,
    [MULTIPLE_ANSWER_WITH_SECTIONS]: MULTIPLE_ANSWER_WITH_SECTIONS,
    [FORM_FORM]: FORM_FORM
}
