import { funnelQuestionTypes as QuestionTypes } from '@puls/puls-common-constants'

export default (selectedService) => {
    var services = [];
    selectedService.questions.forEach((question)=> {
        switch (question.type) {
            case QuestionTypes.STEPPER_SELECTION:
                question.options.forEach((option) => {
                    new Array(option.count).fill(0).forEach((_, index) => {
                        services.push(1658);
                    })
                })
                break;
            default:
        }
    })

    return services
}
