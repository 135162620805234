import {funnelQuestionTypes as QuestionTypes} from '@puls/puls-common-constants'
import _ from 'lodash';
const MIN_HOUR_TIME = 2;


function calculatePriceWithoutDiscount(selectedService, pricesListMap, opts = {}){
    try {
        // the selected service can bean object with id only when services aggregation is called;
        let {id} = selectedService;
        let hourPrice = pricesListMap[id][idsMap["2"]].totalPrice / 2;
        let numberOfHouers = 0;
        if(opts.overrideHours) {
            numberOfHouers = opts.overrideHours;
        } else {
            numberOfHouers = calculateNumberOfHouers(selectedService.questions)
        }
        let servicePrice = pricesListMap[id][idsMap[numberOfHouers]].totalPrice
        if(numberOfHouers === 2) return 5;
        return Math.ceil((numberOfHouers * hourPrice) - servicePrice);
    } catch (e) {
        console.error(e)
    }

    return 0
}


function calculateNumberOfHouers(questions, options = {}){
    let sum = 0;
    if(!(questions && questions.length)) return sum;
    questions.forEach((question) => {
        switch (question.type) {
            case (QuestionTypes.MULTIPLE_ANSWER):
            case (QuestionTypes.MULTIPLE_ANSWER_WITH_SECTIONS):
            case (QuestionTypes.SINGLE_ANSWER):
                question.options.forEach((option) => {
                    if(option.selected && option.quntifiableHourValue) sum += (option.quntifiableHourValue)
                })
                break;
            case (QuestionTypes.FREE_TEXT_ANSWER):
                if(question.quntifiableHourValue) sum += (question.quntifiableHourValue)
                break;
            case (QuestionTypes.STEPPER_SELECTION):
                question.options.forEach((option) => {
                    if(option.quntifiableHourValue) sum += (option.quntifiableHourValue * option.count)
                })
                break;
            default:
        }
    })
    if(sum === 0) return 0;
    if(sum < MIN_HOUR_TIME){
        return MIN_HOUR_TIME;
    } else {
        if(_.isInteger(sum)) return sum; // int
        else {// float
            if(options.noRoundUp) return sum;
            return roundToClosestTopHalf(sum);
        }
    }
}

let idsMap = {
    "2": 1085,
    "3": 1086,
    "4": 1087,
    "5": 1088,
    "6": 1089,
    "7": 1090,
    "8": 1091,
    "9": 1092,
    "10": 1093,
    "2.5": 1095,
    "3.5": 1097,
    "4.5": 1099,
    "5.5": 1101,
    "6.5": 1103,
    "7.5": 1105,
    "8.5": 1107,
    "9.5": 1109
}

let servicesToHoursMap = {};
_.keys(idsMap).forEach((id) => {
    servicesToHoursMap[idsMap[id]] = parseFloat(id);
})

function numberOfHuorsToServiceId(num){
    let sid = 0;
    sid = idsMap[num.toString()];
    if(!sid) sid = idsMap[Math.ceil(num).toString()];
    if(!sid) sid = idsMap[Math.floor(num).toString()];
    if(!sid) sid = 0;
    return sid;
}

function getDeviceProperties(selectedService, options = {}){
    let all = {};
    selectedService.questions.forEach((question) => {
        let key = '';
        if(options.dontReplaceSpance){
            key = question.propertyKey;
        } else {
            key = question.propertyKey.replace(/\s/g, '_');
        }
        let value = '';
        switch (question.type) {
            case (QuestionTypes.SINGLE_ANSWER):
            case (QuestionTypes.MULTIPLE_ANSWER):
            case (QuestionTypes.MULTIPLE_ANSWER_WITH_SECTIONS):
            case (QuestionTypes.AUTOCOMPLETE_SELECTION):
            case (QuestionTypes.MINIMAL_AUTOCOMPLETE_SELECTION):
                value = question.options.filter(a => a.selected && a.text).map(a => a.text).join(', ');
                break;
            case (QuestionTypes.SELECT_COLOR):
                value = question.options.filter(a => a.selected).map(a => a.color_name).join(', ');
                break;
            case (QuestionTypes.FREE_TEXT_ANSWER):
                value = question.value;
                break;
            case (QuestionTypes.FORM_FORM):
                question.options.forEach(o => {
                    if (o.text) {
                        all[o.label] = o.text;
                    }
                });
                break;
            case (QuestionTypes.STEPPER_SELECTION):
                var acc = [];
                question.options.forEach((option) => {
                    if(option.count) acc.push(`${option.text} x${option.count}`);
                });
                value = acc.join(', ');
                break;
            default:
        }

        if(question.optionalText && question.optionalText.value){
            all[question.optionalText.propertyKey] = question.optionalText.value
        }
        if (key && value) {
            all[key] = value;
        }
    });
    return all;
}

function roundToClosestTopHalf(num){
    var floorNum = Math.floor(num);
    var diff = num - floorNum;
    if(diff === 0.5) return num;
    if(diff < 0.5) return (floorNum + 0.5);
    if(diff > 0.5) return (floorNum + 1);
}

export default {
    calculateNumberOfHouers,
    calculatePriceWithoutDiscount,
    numberOfHuorsToServiceId,
    getDeviceProperties,
    servicesToHoursMap
}