import { fields as FieldConstants} from '@puls/puls-common-constants';
import { funnelsActions as actionConstants} from '@puls/puls-common-constants';
import { addressInputs as inputsConstants } from '@puls/puls-common-constants';
import { inputValidators } from '../Services/inputsValidators';
import _ from 'lodash';

let initialState ={
    [FieldConstants.ADDRESS] : '',
    [FieldConstants.ADDITIONAL_ADDRESS] : '',
    [FieldConstants.OPTIONAL] : '',
    isValid : false,
    googleData : null,
    isValidating : false,
    ...inputsConstants
};


export default (state = initialState, action) => {
    let newState = null;
    let isValid = null;
    switch(action.type){
        case (actionConstants.INIT_APP):
            newState = _.cloneDeep(initialState);
            return newState;
        case (actionConstants.UPDATE_USER_ADDRESS_FIELD):
            newState = _.cloneDeep(state);
            newState[action.payload.field] = action.payload.value;
            isValid = true;
            Object.keys(newState.inputs).forEach((field)=>{
                isValid = isValid && inputValidators[newState.inputs[field].validateStrategy](newState[field], newState.googleData);
            });
            newState.isValid = isValid;
            return newState;
        case (actionConstants.UPDATE_GOOGLE_ADDRESS_DATA):
            newState = _.cloneDeep(state);
            newState.isValidating = false;
            newState.googleData = action.payload;
            newState[FieldConstants.ADDRESS] = newState.googleData.formatted_address || newState[FieldConstants.ADDRESS];
            isValid = true;
            Object.keys(newState.inputs).forEach((field)=>{
                isValid = isValid && inputValidators[newState.inputs[field].validateStrategy](newState[field], newState.googleData);
            });
            newState.isValid = isValid;
            return newState;
        case actionConstants.UPDATE_ADDRESS_VALIDATION_INDICATOR:
            newState = _.cloneDeep(state);
            newState.isValidating = action.payload;
            return newState;
        case (actionConstants.SET_PRE_SELECTED_TIME_DEFAULT):
            newState = _.cloneDeep(state);
            newState[FieldConstants.ADDRESS] = action.payload.title;
            newState.googleData = {name : action.payload.title};
            return newState;
        case (actionConstants.CLEAR_TIME_AND_ADDRESS):
            return _.cloneDeep(initialState);
        default:
    }
    return state
}