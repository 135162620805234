import FieldConstants from './fields.js';
import { INPUTS_VALIDATORS_STRATEGIES } from '../inputsValidators.js';

export default {
    inputs: {
        [FieldConstants.ADDRESS]:{
            id: 'address',
            placeholder: 'Address',
            placeholderError: 'Please enter a valid address',
            type: 'text',
            validateStrategy: INPUTS_VALIDATORS_STRATEGIES.ADDRESS_GOOGLE,
            isValid: false
        },
        [FieldConstants.ADDITIONAL_ADDRESS]:{
            id: 'additional',
            placeholder: 'Apt / Suite / Floor No. (optional)',
            placeholderError: 'Please enter valid input',
            type: 'text',
            validateStrategy: INPUTS_VALIDATORS_STRATEGIES.ADDRESS,
            isValid: false
        },
        [FieldConstants.OPTIONAL]:{
            id: 'instructions',
            placeholder: 'Add address instructions (optional)',
            placeholderError: 'Please enter valid input',
            type: 'text',
            isValid: false,
            validateStrategy: INPUTS_VALIDATORS_STRATEGIES.ADDRESS,
        },

    }
};
