import handymanUtils from '../handyman.js';
import customSmartHomeServicesCalculator from './custom/smart-home.js';
import customTVMountingServicesCalculator from './custom/tv-mounting.js';
import customSamsungServicesCalculator from './custom/samsung-appliances.js';
import customHandymanServicesExtractor from './custom/handyman-services-extractor.js';
import customBigAssFans from './custom/big-ass-fans.js';

export default (selectedService, opts = {}) => {
    if (!selectedService) return [];
    // if the funnel is single service funnel return the single service as array
    if(selectedService.isSingleServiceAppointment) return [ selectedService.isSingleServiceAppointment ];
    // if services has special services calculator call the calculatoe function here
    //
    // selectedService.customFunnelServices is the type of calculator that will be used
    //
    // ==========================================================================

    // for now only tvmounting can hold custom fake services in order to
    // filter upsell according to users selections
    if(selectedService.customFunnelServices){
        var services = [];
        switch (selectedService.customFunnelServices) {
            // since samsung and lg are basicly the save calculation type we
            // apply the same strategy on them
            case 'samsung-appliances':
            case 'lg-appliances':
                services = customSamsungServicesCalculator(selectedService);
                break;
            case 'smarthome':
                services = customSmartHomeServicesCalculator(selectedService);
                break;
            case 'tvmounting':
                services = customTVMountingServicesCalculator(selectedService, opts);
                break;
            case 'big-ass-fans':
                services = customBigAssFans(selectedService, opts);
                break;
            default:

        }

        // filter the fake services
        // fake services are in order to not show to the user
        // services depending in the other MultipleSelectionScreen
        if(opts.withFakeService){
            return services
        } else {
            return services.filter(service => {
                return typeof service === 'number'
            })
        }

    }

    // the fallback is to calculate handyman services hours
    var numberOfHours = handymanUtils.calculateNumberOfHouers(selectedService.questions);
    var serviceId = (numberOfHours && handymanUtils.numberOfHuorsToServiceId(numberOfHours));
    var handymanServices = []
    // no coressponding service id;
    if(serviceId) handymanServices.push(serviceId);
    var servicesFromQuestions = customHandymanServicesExtractor(selectedService);
    handymanServices = handymanServices.concat(servicesFromQuestions);
    return handymanServices;
}


/*
// the fallback is to calculate handyman services hours
var numberOfHours = handymanUtils.calculateNumberOfHouers(selectedService.questions);
var handymanServices = []
while (numberOfHours > 0) {
    if(numberOfHours > 10){
        handymanServices.push(handymanUtils.numberOfHuorsToServiceId(10));
        numberOfHours -= 10;
    } else {
        handymanServices.push(handymanUtils.numberOfHuorsToServiceId(numberOfHours));
        numberOfHours -= numberOfHours;
    }
}
// no coressponding service id;
var servicesFromQuestions = customHandymanServicesExtractor(selectedService);
handymanServices = handymanServices.concat(servicesFromQuestions);
return handymanServices;
*/
