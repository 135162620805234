import appstorage from "./Services/appstorage";
import handymanUtils from "./Services/handyman";
import utils from "./Services/utils";
import { funnelCodes, funnelUtilsConstants, translationUtilsConstants } from "@puls/puls-common-constants";

const { STRATEGY_TYPES } = funnelUtilsConstants;
const { COOKIES_NAMES, TRANSLATIONS_LANGUAGES } = translationUtilsConstants;

export default {
    [STRATEGY_TYPES.BLENDER]: {
        getAppointmentState: (appState) => appState.appointment,
        getDeviceProperties: (selectedService, appointment) => {
            const { estimations, selectedEstimationId } = appointment;
            const selectedEstimation = estimations.find(a => a.id === selectedEstimationId);
            const {avg_payment_total, max_range, min_range, avg_payment_services, avg_payment_parts, name } = selectedEstimation;
            const totalEstimatedPrice = utils.priceFormatter(avg_payment_total);

            const deviceProp = { 
                Estimate_information: `Based on the customer’s device issue, our AI cost calculator suggests the repair service be ${name} which should cost around ${totalEstimatedPrice}. This price is subject to change, based on an actual diagnosis of the device.`,
                Estimated_total_price: totalEstimatedPrice
            } 

            if(selectedService.description) {
                deviceProp.CX_issue_description = selectedService.description;
            }
            
            return deviceProp;
        },
        apptReducerName: 'appointment',
        getAppointmentOrigin: () => 'Online booking blender'
    },
    [STRATEGY_TYPES.DEFAULT]: { 
        getAppointmentState: (appState) => appState.handyman,
        getDeviceProperties: (selectedService) => handymanUtils.getDeviceProperties(selectedService),
        apptReducerName: 'handyman',
        getAppointmentOrigin: (appState) => {
            if(appState.config.creating_in_admin)
                return 'admin'
        
            let origin = utils.getCookieByName('origin_tag') || 'Online booking';
            if(utils.getCookieByName(COOKIES_NAMES.LANGUAGE) === TRANSLATIONS_LANGUAGES.es && utils.acuireFunnelId(appState.handyman) === funnelCodes.APPLIANCES_REPAIR.funnelId) { 
                origin = `${origin} - Spanish`;
            } 
            return origin;
        }
    }
}